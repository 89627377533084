<template>
  <BookshelfEntry>
    <rbe-book-import>
      <NoBookCoverPlaceholder
        :title="bookTitle"
        :author="$user.info"
        class="no-book-cover-placeholder"
      >
        <rbe-book-cover-loader>
          <VuiLoadingIndicator class="lg color-accent-alt" />
          <p>Importing...</p>
        </rbe-book-cover-loader>
      </NoBookCoverPlaceholder>
    </rbe-book-import>

    <template #bottom-hover>
      <EntryAction>
        <button
          type="button"
          class="button-text cancel-button cancel-import-button"
          :aria-label="`Cancel import of book with title ${bookTitle}`"
          @click="cancelImport"
        >
          Cancel
        </button>
      </EntryAction>
    </template>
  </BookshelfEntry>
</template>
<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import BookProgress from '@reedsy/studio.home.bookshelf/components/book-progress/book-progress.vue';
import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';
import NoBookCoverPlaceholder from '@reedsy/studio.home.bookshelf/components/book-cover/no-book-cover-placeholder.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {BookshelfEntryType, IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import BookshelfEntry from './bookshelf-entry.vue';
import EntryAction from './entry-action.vue';
import {BooksImportsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-imports';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@Component({
  components: {
    BookCover,
    BookProgress,
    NoBookCoverPlaceholder,
    BookshelfEntry,
    EntryAction,
  },
})
export default class BookImportEntry extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInjectStore(StoreName.BooksImports)
  public $booksImports: BooksImportsModule;

  @$lazyInject('Api')
  public api: IApi;

  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry<BookshelfEntryType.BookImport>;

  public get bookImport(): IBookImport {
    return this.entry.item;
  }

  public get bookTitle(): string {
    return this.bookImport.importData.title;
  }

  public async cancelImport(): Promise<void> {
    await this.$booksImports.cancelBookImport(this.bookImport._id);
  }
}
</script>

<style lang="scss" scoped>
rbe-book-import {
  width: 100%;
  height: 100%;

  rbe-book-cover-loader {
    width: 100%;
    margin-top: $space-xxl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-top:  $space-md;

      @include font-family($controls);

      font-size: $font-size-sm;
      text-transform: none;
    }
  }

  rbe-entry-action {
    height: auto;
  }
}

</style>
