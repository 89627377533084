import {config} from '@reedsy/studio.shared/config';
import {injectable} from 'inversify';
import {$inject} from '@reedsy/studio.shared/types';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {CloseEvent} from '@reedsy/reconnecting-websocket';
import {WebSocketCode} from '@reedsy/studio.isomorphic/models/websocket-code';
import {dig} from '@reedsy/utils.dig';
import {IBookshelfShareDbConnection} from './bookshelf-sharedb-connection.interface';
import WebSocketShareDbConnection from '@reedsy/studio.shared/services/sharedb/web-socket-sharedb-connection';
import {WebsocketConnectionSource} from '@reedsy/studio.isomorphic/models/websocket-connection-source';
import SESSION_ID from '@reedsy/studio.shared/services/sharedb/session-id';

@injectable()
export default class BookshelfShareDbConnection
  extends WebSocketShareDbConnection<string>
  implements IBookshelfShareDbConnection {
  public constructor(
  @$inject('LoggerFactory')
    loggerFactory: LoggerFactory,

    @$inject('Navigation')
    private readonly navigation: INavigation,
  ) {
    super(loggerFactory);
  }

  protected get connectionId(): string {
    return `bookshelf:${SESSION_ID}`;
  }

  protected get source(): WebsocketConnectionSource {
    return WebsocketConnectionSource.Bookshelf;
  }

  protected get socketUrl(): string {
    return `${config.app.server.ws}/bookshelf/sharedb?${this.socketUrlQuery}`;
  }

  protected override onSocketClose(event: CloseEvent): void {
    super.onSocketClose(event);

    if (
      dig(event, 'code') === WebSocketCode.Unauthorized
    ) {
      this.navigation.goToSignIn();
    }
  }
}
