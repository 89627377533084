<template>
  <PlainModal
    :id="id"
    :headless="true"
    class="subscription-modal accent-premium wide no-padding"
  >
    <rbe-columns>
      <rbe-column>
        <h1>
          <span>
            {{ title }}
          </span>
          <PremiumIcon size="xl" />
        </h1>

        <slot name="left" />
      </rbe-column>

      <rbe-column class="right">
        <slot name="right" />
      </rbe-column>
    </rbe-columns>
  </PlainModal>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';

@Component({
  components: {
    PlainModal,
    PremiumIcon,
  },
})
export default class SubscriptionBaseModal extends ClientSharedVue {
  @Prop({required: true})
  public id: string;

  @Prop({required: true})
  public title: string;
}
</script>

<style lang="scss" scoped>
.subscription-modal {
  --column-padding: #{$space-xl};
  --selection-background: var(--accent-color-weak);

  $selection-background: var(--accent-color-weak);

  rbe-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  rbe-column {
    padding: var(--column-padding);
    border-top-right-radius: $modal-border-radius;
    border-bottom-right-radius: $modal-border-radius;
    display: grid;
    gap: $space-sm;
  }

  h1 {
    @include font-family($controls, heavy);

    display: flex;
    align-items: center;
    gap: $space-md;
    justify-content: left;
  }

  .right {
    background: var(--selection-background);
  }

  // TODO: Get mobile/tablet designs
  @include screen-less-than(md) {
    h1 {
      flex-direction: row-reverse;
    }

    rbe-columns {
      grid-template-columns: 1fr;

      .right {
        display: none;
      }
    }
  }
}
</style>
