import ReedsyAnalyticsManager from '@reedsy/analytics-manager';
import {IClientConfig} from '@reedsy/studio.shared/config';
import {$inject} from '@reedsy/studio.shared/types';
import {deepInjectable} from '@reedsy/utils.inversify';
import {setAnalyticsCookie} from './set-analytics-cookie';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {one} from '@reedsy/utils.fluent-date';

@deepInjectable()
export class StudioAnalyticsManager extends ReedsyAnalyticsManager {
  public constructor(
    @$inject('Config')
    private readonly config: IClientConfig,

    @$inject('Api')
    private readonly api: IApi,
  ) {
    super({
      gtm: {
        id: config.gtm.containerId,
        src: config.gtm.tagScriptSrc,
      },
    });
  }

  public triggerAnalytics(): void {
    // This promise may never resolve if user has some ad blocker installed
    setAnalyticsCookie(this.config).then(() => {
      this.api.triggerAnalytics();
      // Make sure we trigger this once per day in case users just
      // leave the same tab open all the time
      setInterval(() => this.api.triggerAnalytics(), +one.day);
    });
  }
}
