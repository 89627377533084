import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-14264caa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumIcon = _resolveComponent("PremiumIcon")!
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    headless: true,
    class: "subscription-modal accent-premium wide no-padding"
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-columns", null, [
        _createElementVNode("rbe-column", null, [
          _createElementVNode("h1", null, [
            _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
            _createTextVNode(),
            _createVNode(_component_PremiumIcon, { size: "xl" })
          ]),
          _createTextVNode(),
          _renderSlot(_ctx.$slots, "left", {}, undefined, true)
        ]),
        _createTextVNode(),
        _createElementVNode("rbe-column", _hoisted_1, [
          _renderSlot(_ctx.$slots, "right", {}, undefined, true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["id"]))
}