<template>
  <rbe-share-indicator
    v-if="isShared"
    :class="size"
  >
    <VuiIconPeople
      v-if="isCurrentUserOwner"
      ref="people-icon"
      :class="{
        [`icon-${size}`]: !!size
      }"
    />
    <Avatar
      v-else
      ref="avatar"
      :user-info="bookOwner"
      bordered
      class="collaborator-avatar"
      :class="size"
    />
  </rbe-share-indicator>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {IBookDetails} from '@reedsy/reedsy-sharedb/lib/common/book/book-details';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';

@Component({
  components: {
    Avatar,
  },
})
export default class BookEntry extends BookshelfVue {
  @Prop({type: String, required: true})
  public bookId: string;

  @Prop({type: String, default: ''})
  public size: string;

  @$lazyInjectStore(SharedStoreName.User)
  public readonly $user: SharedUserModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public readonly $collaborators: BookshelfCollaboratorsModule;

  @$lazyInjectStore(StoreName.BookDetails)
  public readonly $bookDetails: BookDetailsModule;

  public get isShared(): boolean {
    return this.$bookDetails.isShared(this.bookId);
  }

  public get isCurrentUserOwner(): boolean {
    return this.currentUserRole === ResourcesRole.Owner;
  }

  public get currentUserRole(): ResourcesRole {
    return this.$bookDetails.userBookRole({bookId: this.bookId, userUuid: this.$user.uuid});
  }

  public get bookOwner(): IUserInfo {
    return this.$collaborators.userById[this.book.ownerId];
  }

  public get book(): IBookDetails {
    return this.$bookDetails.details(this.bookId);
  }
}
</script>

<style lang="scss" scoped>
$position-multiplier: -0.3;

rbe-share-indicator {
  --bookshelf-share-indicator-size: 2.5rem;

  border-radius: 50%;
  width: var(--bookshelf-share-indicator-size);
  height: var(--bookshelf-share-indicator-size);
  top: calc($position-multiplier * var(--bookshelf-share-indicator-size));
  right: calc($position-multiplier * var(--bookshelf-share-indicator-size));
  background-color: color($white);
  color: #{color($slate, dark)};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: $box-shadow-base;
  position: absolute;

  &.sm {
    --bookshelf-share-indicator-size: 1.5rem;
  }
}

</style>
