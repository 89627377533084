import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportsList = _resolveComponent("ExportsList")!
  const _component_BlurbPrintOnDemand = _resolveComponent("BlurbPrintOnDemand")!

  return (_openBlock(), _createElementBlock("rbe-book-exports-list-view", null, [
    _createVNode(_component_ExportsList),
    _createTextVNode(),
    _createVNode(_component_BlurbPrintOnDemand)
  ]))
}