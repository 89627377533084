import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {SharedModals} from '@reedsy/studio.shared/store/modules/modals/base';
import {CANCELABLE_MODALS} from '@reedsy/studio.home.bookshelf/components/modals/cancelable-modals';
import {BookshelfModalContextMap} from '@reedsy/studio.home.bookshelf/components/modals/modal-type-object-map';
import IModal from '@reedsy/studio.shared/store/modules/modals/i-modal';

@injectable()
export class BookshelfModalsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('ModalsData')
    modalsData: Record<string, IModal>,
  ) {
    @Module({name: StoreName.Modals, store})
    class BookshelfModalsModule extends SharedModals<BookshelfModalContextMap> {
      public override readonly modalsById: {[id: string]: IModal} = modalsData;
      protected override readonly cancelableModals = CANCELABLE_MODALS;
    }

    this.Module = BookshelfModalsModule;
  }
}

export type BookshelfModalsModule = InstanceType<BookshelfModalsModuleFactory['Module']>;
