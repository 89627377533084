import tinycolor from 'tinycolor2';

export default class ColorPool {
  public static readonly COLORS = Object.freeze([
    '#0090CD', // Blue
    '#C206E3', // Purple
    '#FF1E21', // Red
    '#E17900', // Orange
    '#000EF2', // Navy
    '#FF29CB', // Pink
    '#AA9328', // Yellow
  ]);

  public static readonly USER_GREEN = '#299B00';

  private availableColors = ColorPool.COLORS.slice(0);
  private takenColors = new Set<string>();

  public get(): string {
    const color = this.availableColors.shift() || this.randomColor();
    this.takenColors.add(color.toUpperCase());
    return color;
  }

  private randomColor(): string {
    let color: string;
    while (!this.isReadable(color)) {
      color = tinycolor.random().toHexString().toUpperCase();
      if (this.takenColors.has(color)) color = null;
    }

    return color;
  }

  private isReadable(color: string): boolean {
    if (!color) return false;
    // TODO: Check if we need to observe this value updates in dark theme
    const foreColor = window.getComputedStyle(document.documentElement).getPropertyValue('--TEXT-BACKGROUND');
    const readable = tinycolor.isReadable(foreColor, color);
    return readable;
  }
}
