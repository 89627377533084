import {Action, VuexModule} from '@reedsy/vuex-module-decorators';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {OpSource} from '@reedsy/studio.isomorphic/models/op-source';
import {IShareDbModule} from '@reedsy/studio.shared/store/modules/sharedb/i-share-db-module';
import {Store} from 'vuex';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {IFeedbackResponse} from '@reedsy/reedsy-sharedb/lib/common/feedback/feedback-response';
import {BaseUserSettingsModule} from '@reedsy/studio.shared/store/modules/user-settings/user-settings-module-factory';

// eslint-disable-next-line @stylistic/max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function feedbackModuleFactory(
  store: Store<any>,
  $shareDB: IShareDbModule<'userSettings' | 'feedbackResponses'>,
  $user: SharedUserModule,
  $userSettings: BaseUserSettingsModule,
) {
  @Module({name: SharedStoreName.Feedback, store})
  class BaseUserSettingsModule extends VuexModule {
    @Action
    public async skipFeedbackSurvey(): Promise<void> {
      await $userSettings.markFeedbackSurveyAsSkipped();
    }

    @Action
    public async submitFeedback(feedback: IFeedbackResponse): Promise<void> {
      await $shareDB.create({
        collection: 'feedbackResponses',
        id: $user.id,
        source: OpSource.Store,
        data: {
          ...feedback,
          richTextSchemaVersion: 4,
          schemaVersion: 1,
          metadata: {
            userId: $user.id,
          },
        },
      });
      await $userSettings.markFeedbackSurveyAsFilled();
    }
  }

  return BaseUserSettingsModule;
}
