<template>
  <rbe-image-uploader
    :class="{
      'dropping': isDropping,
      'uploading': isUploading,
    }"
    class="panel"
  >
    <rbe-upload-progress-bar v-if="isUploading">
      <VuiLoadingIndicator
        v-if="uploadProgress === null"
        class="lg"
      />

      <VuiProgressBar
        v-else
        class="sm"
        :percentage="uploadProgress"
      />
    </rbe-upload-progress-bar>

    <rbe-uploader-content>
      <img
        v-if="hasImage"
        :src="url"
      >
      <img
        v-else
        src="@/assets/inlined/book-cover-placeholder.png"
      >

      <h1>
        Upload a book cover
      </h1>

      <button
        class="button-text button-accent button-with-icon"
        type="button"
        @click="pickFile"
      >
        <span>Upload a file</span>
        <VuiIconUpload class="icon-black" />
      </button>

      <button
        v-if="hasImage"
        type="button"
        class="remove-file link"
        title="Remove cover"
        @click="removeFile"
      >
        Remove cover
      </button>
    </rbe-uploader-content>

    <rbe-drop-indicator
      v-if="(!isUploading && isDropping)"
    />

    <input
      ref="fileInput"
      :accept="acceptedFileTypes"
      type="file"
    >
  </rbe-image-uploader>
</template>

<script lang="ts">
import {Component, Emit, Prop, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {VuiFileUpload} from '@reedsy/studio.shared/mixins/vui/vui-file-upload';

@Component({})
export default class ImageUploader extends mixins(BookshelfVue, VuiFileUpload) {
  @Prop({type: String, required: true})
  public url: string;

  public get hasImage(): boolean {
    return !!this.url;
  }

  @Emit('remove-file')
  public removeFile(): void {
    return;
  }
}
</script>

<style lang="scss" scoped>
rbe-image-uploader.panel {
  border-radius: $border-radius-lg;
  border: $border-width-lg dashed $border-color;
  padding: 2rem;
  position: relative;
  text-align: center;
  background-color: var(--TEXT-BACKGROUND);
  box-shadow: none;

  &[disabled],
  [disabled] &,
  &:disabled,
  :disabled & {
    opacity: 0.5;
    pointer-events: none;
  }

  &.dropping {
    background-color: var(--TEXT-BACKGROUND);
  }

  &.dropping,
  &.uploading {
    rbe-uploader-content {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.uploading rbe-uploader-content {
    opacity: 0,
  }

  img {
    $height: 10rem;

    height: $height;
    width: $height * $cover-aspect-ratio;
    box-shadow: $box-shadow-base;
    border-radius: $border-radius-lg;
  }

  rbe-uploader-content {
    h1 {
      padding: $space-lg 0;

      @include font-family($controls, bold);

      font-size: $font-size-lg;
    }

    .remove-file {
      display: block;
      margin: 1rem auto 0;
    }
  }

  rbe-upload-progress-bar {
    margin: $space-lg 0;
    left: 50%;
    min-width: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

  }

  rbe-drop-indicator {
    content: ' ';
    height: 10rem;
    width: 15rem;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
    background: url('~@vui-assets/drop-placeholder.svg') no-repeat;
  }

  input[type='file'] {
    display: none;
  }
}
</style>
