import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {UserSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-settings';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {IClientConfig} from '@reedsy/studio.shared/config';

@injectable()
export default class AfterSignUpSurveyPluginFactory extends RouterPlugin {
  @$inject('Config')
  public readonly config: IClientConfig;

  @$inject('StoreModule')
  @named(StoreName.UserSettings)
  public readonly _userSettings: UserSettingsModule;

  @$inject('StoreModule')
  @named(StoreName.Modals)
  public readonly _modals: BookshelfModalsModule;

  public override async beforeEach(): Promise<void> {
    if (this._userSettings.isAfterSignUpSurveySkipped) return;
    if (this._userSettings.isAfterSignUpSurveyCompleted) return;
    if (this._modals.hasOpenModals) return;

    await this._modals.OPEN('AfterSignUpSurvey');
  }
}
