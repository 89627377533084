import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {config} from '@reedsy/studio.shared/config';

export const ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING: Record<string, string> = {
  [BookshelfRouteName.GeneralAccountSettings]: 'General',
};

/* istanbul ignore next: can't test without changing from const to method, which is overkill */
if (config.features.subscriptions) {
  ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING[BookshelfRouteName.SubscriptionAccountSettings] = 'Subscription';
}
