
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({})
export default class Panel extends BookshelfVue {
  @Prop({type: String, default: ''})
  public title: string;

  @Prop({type: String, default: ''})
  public bodyTitle: string;

  public get hasFooter(): boolean {
    return !!this.$slots.footer;
  }

  public get hasTitle(): boolean {
    return !!this.$slots.title || !!this.title;
  }
}
