import {injectable, named} from 'inversify';
import {Store} from 'vuex';
import {Op} from 'sharedb/lib/client';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {userSettingsModuleFactory} from '@reedsy/studio.shared/store/modules/user-settings/user-settings-module-factory';
import BookshelfStoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

@injectable()
export class UserSettingsModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    User: SharedUserModule,

    @$inject('StoreModule')
    @named(BookshelfStoreName.ShareDb)
    ShareDB: ShareDBModule,
  ) {
    this.Module = userSettingsModuleFactory(
      StoreName.UserSettings,
      store,
      ShareDB,
      User,
    );
  }
}

export type UserSettingsModule = InstanceType<UserSettingsModuleFactory['Module']>;
export interface ISubmitOp {
  id: string; op: Op[];
}
