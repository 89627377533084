import {AxiosError} from 'axios';
import {injectable} from 'inversify';
import {Timers} from '@reedsy/studio.shared/utils/timers';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import Api from './api';
import {config} from '@reedsy/studio.shared/config';
import {$inject} from '@reedsy/studio.shared/types';
import Notify from '@reedsy/studio.shared/services/notify/notify';

@injectable()
export default class RedirectingApi extends Api {
  public constructor(
  @$inject('LoggerFactory') loggerFactory: LoggerFactory,
    @$inject('Navigation') private readonly navigation: INavigation,
  ) {
    super(loggerFactory);
  }

  protected override async handleUnauthorized(error: AxiosError): Promise<void> {
    await this.redirectToLoginInDevEnv();
    await super.handleUnauthorized(error);
    await this.navigation.goToSignIn();
  }

  protected override async handleForbidden(error: AxiosError): Promise<void> {
    await this.redirectToLoginInDevEnv();
    await super.handleForbidden(error);
    await this.navigation.goToBookShelf();
  }

  protected override async handleTooManyRequestsError(error: AxiosError): Promise<void> {
    await super.handleTooManyRequestsError(error);
    Notify.error({
      message: 'You tried to do this action too many times. Please wait a while and try again later.',
    });
  }

  private async redirectToLoginInDevEnv(): Promise<void> {
    if (!config.env.development) return;
    if (config.api.real) return;

    window.open(config.app.home.developmentLoginPath, '_self');
    await Timers.forever();
  }
}
