<template>
  <rbe-books-slider>
    <rbe-slide-control
      class="books-slider-button-prev"
    >
      <ScreenEdgeArrow class="left" />
    </rbe-slide-control>

    <rbe-slide-control
      class="books-slider-button-next"
    >
      <ScreenEdgeArrow class="right" />
    </rbe-slide-control>

    <swiper-container
      ref="swiper"
      class="swiper-books-slider"
      effect="coverflow"
      grab-cursor="true"
      centered-slides="true"
      slides-per-view="auto"
      space-between="30"
      events-prefix="swiper-"
      coverflow-effect-rotate="20"
      coverflow-effect-scale="0.9"
      coverflow-effect-stretch="0"
      coverflow-effect-depth="100"
      coverflow-effect-modifier="1"
      coverflow-slide-shadows="false"
      scrollbar-enabled
      scrollbar-draggable="true"
      scrollbar-snap-on-release="true"
      navigation-enabled="true"
      navigation-prev-el=".books-slider-button-prev"
      navigation-next-el=".books-slider-button-next"
      @swiper-scrollbardragend="updateActiveEntry"
      @swiper-slidechange="updateActiveEntry"
    >
      <swiper-slide
        v-for="entry in bookshelfEntries"
        :key="entry.id"
      >
        <component
          :is="componentMap[entry.type]"
          :entry="entry"
        />
      </swiper-slide>
    </swiper-container>
  </rbe-books-slider>
</template>

<script lang="ts">
import {Component, Ref, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfEntryType, IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import BookImportEntry from './book-import-entry.vue';
import BookEntry from './book-entry.vue';
import ScreenEdgeArrow from '@reedsy/studio.shared/components/screen-edge-arrow/screen-edge-arrow.vue';
import {Swiper} from 'swiper';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';

const BOOKSHELF_ENTRY_COMPONENT_MAPPING = Object.freeze({
  [BookshelfEntryType.Book]: 'BookEntry',
  [BookshelfEntryType.BookImport]: 'BookImportEntry',
} as const satisfies Record<BookshelfEntryType, string>);

const SHOW_MORE_ENTRIES_THRESHOLD = 3;

interface ISwiperHtmlElement extends HTMLElement {
  swiper: Swiper;
}

@Component({
  components: {
    BookImportEntry,
    BookEntry,
    ScreenEdgeArrow,
  },
})
export default class BooksSlider extends BookshelfVue {
  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Ref('swiper')
  public swiperElement: ISwiperHtmlElement;

  public swiper: Swiper;
  public readonly componentMap = BOOKSHELF_ENTRY_COMPONENT_MAPPING;

  public get bookshelfEntries(): IBookshelfEntry[] {
    return this.$bookshelf.displayedBookshelfEntries;
  }

  public mounted(): void {
    this.swiper = this.swiperElement.swiper;
  }

  @Watch('bookshelfEntries', {immediate: true})
  public async updateSlides(): Promise<void> {
    await this.$nextTick();
    this.swiper?.update();
  }

  @Watch('$bookshelf.activeEntryIndex')
  public async setSwiperIndex(): Promise<void> {
    this.slideTo(this.$bookshelf.activeEntryIndex);
  }

  public updateActiveEntry({detail}: any): void {
    const {activeIndex} = detail[0];
    const entryId = this.bookshelfEntries[activeIndex]?.id;
    this.$bookshelf.setActiveEntry({entryId});
    this.showMoreEntriesIfNeeded();
  }

  private showMoreEntriesIfNeeded(): void {
    const index = this.swiper.activeIndex;
    if (index <= this.bookshelfEntries.length - SHOW_MORE_ENTRIES_THRESHOLD) return;
    this.$bookshelf.showMoreBookshelfEntries();
  }

  private slideTo(index: number): void {
    if (!this.swiper) return;
    if (this.swiper.activeIndex === index) return;
    this.swiper.slideTo(index);
  }
}
</script>

<style lang="scss">
swiper-container{
  &::part(container) {
    overflow-y: visible;
    padding-bottom: 4rem;
  }

  &::part(scrollbar) {
    bottom: 0;

    --swiper-scrollbar-drag-bg-color: var(--NEUTRAL-COLOR);
    --swiper-scrollbar-size: 0.5rem;
    --swiper-scrollbar-bg-color: transparent;
    --swiper-scrollbar-sides-offset: 0.75rem;
  }
}
</style>

<style lang="scss" scoped>
@import '../mixins';

rbe-books-slider {
  position: relative;
  flex: 1 1 auto;

  .swiper-books-slider {
    height: $book-slide-height;
    width: 100%;
  }

  rbe-slide-control {
    cursor: pointer;
    position: absolute;
    width: 3.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: $books-slider-title-height;
    box-sizing: border-box;
    z-index: $z-index-books-slider-controls;

    rbe-screen-edge-arrow {
      overflow: hidden;
    }

    &:hover :deep(.arrow-background #icon-fill) {
      fill: var(--NEUTRAL-COLOR);
    }

    &.swiper-button-disabled {
      display: none;
    }

    &.books-slider-button-prev, &.books-slider-button-next {
      top: 0;
      height: 100%;
    }

    // Arrow icon is outside of the box, this causes the whole component to shift
    $arrow-icon-out-of-box-size: 0.5625rem;

    &.books-slider-button-prev {
      left: -#{$arrow-icon-out-of-box-size};
    }

    &.books-slider-button-next {
      right: -#{$arrow-icon-out-of-box-size};
    }
  }

  swiper-slide {
    @include books-slider-entry-size;

    :deep(.share-indicator) {
      z-index: $z-index-books-slider-controls + 1;

      &::after {
        content: '';
        position: absolute;
        background-color: var(--studio-overlayColor);
        border-radius: 50%;
        opacity: 0;

        @include size(100%);
        @include transition(opacity);
      }
    }

    &.swiper-slide-visible:not(.swiper-slide-active) :deep(.share-indicator::after) {
      opacity: 0.5;
    }
  }
}
</style>
