import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {memoize} from '@reedsy/utils.object';
import {injectable, named} from 'inversify';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {Store} from 'vuex';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserColorModule} from '@reedsy/studio.shared/store/modules/user-color';
import {ILoadUserData, IUsersLoader} from './i-user-loader';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@injectable()
export class BookshelfCollaboratorsModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('UsersLoader')
    usersLoader: IUsersLoader,

    @$inject('Api')
    api: IApi,

    @$inject('Navigation')
    navigation: INavigation,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    User: SharedUserModule,

    @$inject('StoreModule')
    @named(SharedStoreName.UserColor)
    UserColor: SharedUserColorModule,
  ) {
    @Module({name: StoreName.Collaborators, store})
    class BookshelfCollaborators extends VuexModule {
      public userInfoByUuid: {[userUuid: string]: IUserInfo} = {};

      public get userById(): {[userId: string]: IUserInfo} {
        return Object
          .values(this.userInfoByUuid)
          .reduce(
            (userById, userInfo) => {
              userById[userInfo._id] = userInfo;
              return userById;
            },
            {} as {[userId: string]: IUserInfo},
          );
      }

      public get isCollaboratorLoaded() {
        return (userUuid: string): boolean => {
          return !!this.userInfoByUuid[userUuid];
        };
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        await User.initialise();
        await this.setUserInfoWithColor(User.info);
      }

      @Action
      public async logout(): Promise<void> {
        await api.signOut();
        navigation.goToExternalUrl('/');
      }

      @Action
      public async loadContributor(
        {bookUuid, contributorUuid}: ILoadUserData,
      ): Promise<void> {
        if (this.userInfoByUuid[contributorUuid]) return;

        const user = await usersLoader.loadUser({bookUuid, contributorUuid});
        if (!user) return;
        this.setUserInfoWithColor(user);
      }

      @Action
      public async setUserInfoWithColor(userInfo: IUserInfo): Promise<void> {
        const color = await UserColor.getUserColor(userInfo._id);
        this.UPSERT_USER_INFO({...userInfo, color});
      }

      @Mutation
      private UPSERT_USER_INFO(userInfo: IUserInfo): void {
        this.userInfoByUuid[userInfo.uuid] = userInfo;
      }
    }

    this.Module = BookshelfCollaborators;
  }
}

export type BookshelfCollaboratorsModule = InstanceType<BookshelfCollaboratorsModuleFactory['Module']>;

export interface IUserPresence {
  presenceId: string; contentId: string;
}
