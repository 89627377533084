type Fn = (...args: any[]) => any;
export type DisposableFunction<F extends Fn = Fn> = F & {
  _called: boolean;
  [Symbol.dispose]: () => ReturnType<F>;
  [Symbol.asyncDispose]: () => ReturnType<F>;
};

export function finallyCall<F extends Fn>(
  fn: F,
  ...value: Parameters<F>
): DisposableFunction<() => ReturnType<F>> {
  const caller = function() {
    caller._called = true;
    return fn(...value);
  } as DisposableFunction<F>;

  caller._called = false;
  caller[Symbol.dispose] = () => {
    if (!caller._called) return caller();
  };

  return caller;
}
