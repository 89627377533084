<!-- Use v-show instead of v-if to animate the modals -->
<template>
  <transition name="fade">
    <div
      v-show="modals.length"
      class="modal-container"
    >
      <VuiFocusTrap :is-active="!!modals.length">
        <div>
          <Overlay @click="closeAll" />
          <TransitionGroup name="zoom">
            <div
              v-for="modal in modals"
              :key="modal.id"
              class="modal-wrapper"
            >
              <component
                :is="modalComponent(modal.type)"
                :id="modal.id"
                :context="modal.context"
              />
            </div>
          </TransitionGroup>
        </div>
      </VuiFocusTrap>
    </div>
  </transition>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import IModal from '@reedsy/studio.shared/store/modules/modals/i-modal';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import handleKeyboardEvent from '@reedsy/studio.shared/utils/keyboard/handle-keyboard-event';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {modalComponentName} from './modal-component-name';

@Component({
  components: {
    Overlay,
  },
})
export default class ModalContainer extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public get modals(): IModal[] {
    return this.$modals.modals;
  }

  public mounted(): void {
    window.addEventListener('keydown', (event) => handleKeyboardEvent(
      event,
      {key: Key.Escape, handler: this.closeAll},
    ));
  }

  public modalComponent(type: string): string {
    return modalComponentName(type);
  }

  public closeAll(): void {
    this.$modals.CLOSE_ALL();
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  inset: 0;
  z-index: $z-index-modals;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-wrapper {
    display: none;

    &:last-child {
      display: flex;
    }
  }
}
</style>
