import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-638fabda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button button-icon button-accent" }
const _hoisted_2 = { class: "button button-icon button-translucent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconPlus = _resolveComponent("VuiIconPlus")!
  const _component_VuiIconCloud = _resolveComponent("VuiIconCloud")!

  return (_openBlock(), _createElementBlock("rbe-new-book-action-bar", null, [
    _createElementVNode("button", {
      type: "button",
      class: "button-transparent create-book-button ignore-hover",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openCreateBookModal && _ctx.openCreateBookModal(...args)))
    }, [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_VuiIconPlus)
      ]),
      _createTextVNode("\n      Create book\n    ")
    ]),
    _createTextVNode(),
    _createElementVNode("button", {
      type: "button",
      class: "button-transparent import-book-button ignore-hover",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openImportBookModal && _ctx.openImportBookModal(...args)))
    }, [
      _createTextVNode("\n      Import\n      "),
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_VuiIconCloud)
      ])
    ])
  ]))
}