import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import {IBooksDetails} from '@reedsy/reedsy-sharedb/lib/common/book/books-details';
import {IBookDetails} from '@reedsy/reedsy-sharedb/lib/common/book/book-details';
import {clone} from '@reedsy/utils.clone';
import {getAllBookContributorsUuids} from './get-all-book-contributors';
import {Doc} from 'sharedb/lib/client';
import {BooksTotalWordCountsModule} from '@reedsy/studio.home.bookshelf/store/modules/books-total-word-counts';
import {memoize} from '@reedsy/utils.object';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {IUpdateBookRequest} from '@reedsy/studio.isomorphic/models/update-book-request';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSavesModule} from '@reedsy/studio.shared/store/modules/saves';
import {getUserRoleFromContributors} from '@reedsy/studio.isomorphic/helpers/get-user-role-from-contributors';
import {getAllContributors} from '@reedsy/studio.isomorphic/helpers/get-all-contributors';
import {ICollaboratorInfo} from './i-collaborator-info';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

@injectable()
export class BookDetailsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.Collaborators)
    users: BookshelfCollaboratorsModule,

    @$inject('StoreModule')
    @named(StoreName.ShareDb)
    shareDb: ShareDBModule,

    @$inject('StoreModule')
    @named(SharedStoreName.Saves)
    savesModule: SharedSavesModule,

    @$inject('Api')
    api: IApi,

    @$inject('StoreModule')
    @named(StoreName.BooksTotalWordCounts)
    booksTotalWordCounts: BooksTotalWordCountsModule,
  ) {
    @Module({name: StoreName.BookDetails, store})
    class BookDetails extends VuexModule {
      private booksDetailsData: {[bookId: string]: IBooksDetails} = {};

      public get isSaving(): boolean {
        return savesModule.isSaving;
      }

      public get details() {
        return (bookId: string): IBookDetails => {
          if (!this.booksDetailsData[bookId]) return null;
          return this.booksDetailsData[bookId].details;
        };
      }

      public get bookId() {
        return (bookUuid: string): string => {
          const foundBook = Object.values(this.booksDetailsData).find((book) => book.details.uuid === bookUuid);
          if (!foundBook) return null;
          return foundBook.details.id;
        };
      }

      public get bookUuid() {
        return (bookId: string): string => {
          return this.booksDetailsData[bookId]?.details.uuid || null;
        };
      }

      public get userBookRole() {
        return ({userUuid, bookId}: {userUuid: string; bookId: string}): ResourcesRole => {
          const bookDetails = this.details(bookId);
          return getUserRoleFromContributors(bookDetails?.contributors, userUuid);
        };
      }

      public get collaborators() {
        return (bookId: string): ICollaboratorInfo[] => {
          const bookData = this.details(bookId);
          const allCollaboratorsUuid = getAllContributors(bookData.contributors);

          return Array.from(allCollaboratorsUuid).map((userUuid) => {
            return {
              uuid: userUuid,
              role: this.userBookRole({userUuid, bookId}),
              userInfo: users.userInfoByUuid[userUuid],
            };
          });
        };
      }

      public get isShared() {
        return (bookId: string): boolean => {
          const bookDetails = this.details(bookId);
          return getAllContributors(bookDetails?.contributors).size > 1;
        };
      }

      public get books(): IBooksDetails[] {
        return Object.values(this.booksDetailsData);
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        await this.subscribeToBooksDetails();
      }

      @Mutation
      public DATA({data}: IDataMutation<'booksDetails'>): void {
        this.booksDetailsData[data.details.id] = clone(data);
      }

      @Mutation
      public APPLY_OPS({id, ops}: IApplyOpsMutation<'booksDetails'>): void {
        ops.forEach((op) => applyOp(this.booksDetailsData[id], op));
      }

      @Action
      public async updateBookDetails({bookId, body}: {bookId: string; body: IUpdateBookRequest}): Promise<void> {
        try {
          savesModule.START_SAVING(bookId);
          await api.updateBook(bookId, body);
          Notify.success({
            message: 'Book details updated',
          });
        } catch {
          Notify.error({
            message: 'Cannot update book details',
          });
        } finally {
          savesModule.DONE_SAVING({id: bookId});
        }
      }

      @Action
      private async subscribeToBooksDetails(): Promise<void> {
        const query = await shareDb.subscribeQuery({
          collection: 'booksDetails',
        });

        const subscribeDocs = async (docs: Doc<IBooksDetails>[]): Promise<void> => {
          docs.forEach((doc) => {
            subscribeVuexToDoc(doc, this);

            doc.on('op batch', () => this.refreshContributors());
            doc.on('load', () => this.refreshContributors());
          });

          await this.refreshContributors();
        };

        await subscribeDocs(query.results);
        query.on('insert', subscribeDocs);

        query.on('remove', (removedDocs) => {
          removedDocs.forEach((doc) => {
            this.removeBookData(doc.id);
            doc.destroy();
          });
        });
      }

      @Action
      private async refreshContributors(): Promise<void> {
        const loadContributorsPromises = this.books
          .map((book) => {
            return {
              bookUuid: book.details.uuid,
              contributorsUuids: getAllBookContributorsUuids(book),
            };
          })
          .map(({bookUuid, contributorsUuids}) => {
            return [...contributorsUuids]
              .map((contributorUuid) => users.loadContributor({bookUuid, contributorUuid}));
          })
          .flat();

        await Promise.all(loadContributorsPromises);
      }

      @Action
      private async removeBookData(bookId: string): Promise<void> {
        await booksTotalWordCounts.removeBookTotalWordCount(bookId);
        this.REMOVE_BOOKS_DETAILS(bookId);
      }

      @Mutation
      private REMOVE_BOOKS_DETAILS(bookId: string): void {
        delete this.booksDetailsData[bookId];
      }
    }
    this.Module = BookDetails;
  }
}

export type BookDetailsModule = InstanceType<BookDetailsModuleFactory['Module']>;
