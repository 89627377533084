<template>
  <button
    class="top-bar-button cancel-button button-icon"
  >
    <component
      :is="icon"
      class="icon-md icon"
    />
  </button>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import SunIcon from '@reedsy/studio.shared/components/icons/icon-sun.vue';
import MoonIcon from '@reedsy/studio.shared/components/icons/icon-moon.vue';

@Component({
  components: {
    SunIcon,
    MoonIcon,
  },
})
export default class TopBarButton extends BookshelfVue {
  @Prop({type: String, required: true})
  public icon: string;
}
</script>

<style lang="scss" scoped>
.top-bar-button {
  @include transition(background-color);

  &.transparent {
    background-color: unset;
  }

  .icon :deep(#icon-fill) {
    fill: var(--TEXT-INVERTED-COLOR);
  }
}
</style>
