<template>
  <rbe-tick-icon class="flex-centered">
    <VuiIconTick class="icon-xs" />
  </rbe-tick-icon>
</template>

<style lang="scss" scoped>
rbe-tick-icon {
  color: var(--TEXT-INVERTED-COLOR);
  background: var(--accent-color);
  border-radius: 50%;

  @include size(1.25rem);
}
</style>
