<template>
  <rbe-entry-action>
    <slot />
  </rbe-entry-action>
</template>

<style lang="scss" scoped>
rbe-entry-action {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: $books-grid-entry-bottom-space;
  box-sizing: border-box;

  &.blurred-background {
    height: auto;
    padding: $space-md $space-md math.div($books-grid-entry-bottom-space, 2) $space-md;
    margin: $space-lg $space-md math.div($books-grid-entry-bottom-space, 2) $space-md;
    border-radius: $border-radius-lg;
    font-size: $font-size-sm;
    color: $cover-placeholder-text-color;

    @include font-family($controls);

    &.with-cover-image {
      background-color: $books-grid-blur-background-color;
      padding-left: $space-sm;
      padding-right: $space-sm;
      backdrop-filter: $books-grid-blur-filter-amount;
      color: var(--TEXT-INVERTED-COLOR);
    }
  }
}
</style>
