import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3267213a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "xs error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["error-message-wrapper", {'is-visible': !!_ctx.error}])
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.error), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}