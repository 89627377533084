<template>
  <fieldset
    class="tabs"
  >
    <button
      v-for="tab in tabs"
      :key="tab.value"
      type="button"
      class="tab-wrapper"
      @click="selected = tab.value"
    >
      <span
        :ref="`tab-${tab.value}`"
        class="tab"
        :class="{
          active: tab.value === selected,
          [`tab-${tab.value}`]: true
        }"
      >
        <slot
          :name="`tab-${tab.value}`"
          :tab="tab"
          :active="tab.value === selected"
        >
          <span>{{ tab.text }}</span>
        </slot>
      </span>
    </button>
    <rbe-active-tab-underline
      :style="underlineStyles"
    />
  </fieldset>
</template>

<script lang="ts">
import {Component, Prop, Watch, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ITabItem} from './i-tab-item';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class Tabs extends ClientSharedVue {
  @Prop({type: Array<ITabItem>, required: true})
  public tabs: ITabItem[];

  @Model({type: [Number, String]})
  public selected: number | string;

  public underlineWidth = 0;
  public underlineLeft = 0;

  private resizeObserver = new ResizeObserver(this.updateUnderline);

  public get underlineStyles(): any {
    return {
      width: `${this.underlineWidth}px`,
      left: `${this.underlineLeft}px`,
    };
  }

  @Watch('selected')
  public watchSelected(): void {
    this.updateUnderline();
  }

  public async mounted(): Promise<void> {
    this.resizeObserver.observe(this.$el);
    await this.$nextTick();
    this.updateUnderline();
  }

  public beforeUnmount(): void {
    this.resizeObserver.disconnect();
  }

  public getActiveTabDiv(): HTMLDivElement {
    return (this.$refs as any)[`tab-${this.selected}`]?.[0];
  }

  public updateUnderline(): void {
    const activeElement = this.getActiveTabDiv();

    if (!activeElement) {
      this.underlineWidth = 0;
      this.underlineLeft = 0;
      return;
    }

    const rectangle = activeElement.getBoundingClientRect();
    this.underlineWidth = rectangle.width;
    this.underlineLeft = rectangle.x - this.$el.getBoundingClientRect().x;
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  display: flex;
  height: 100%;

  .tab-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $space-md;
    cursor: pointer;

    @include transition(background-color);

    .tab {
      height: 100%;
      padding-top: $space-xs;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-bottom: $space-xs solid transparent;
    }

    &:hover {
      .tab {
        border-bottom: $space-xs solid #{color($blue, base, $alpha: 0.4)};
      }
    }
  }

  rbe-active-tab-underline {
    position: absolute;
    height: $space-xs;
    background-color: var(--local-color-default);
    bottom: 0;

    @include transition((
      width,
      left,
      opacity
    ));
  }

  &:disabled rbe-active-tab-underline {
    opacity: 0.5;
  }
}
</style>
