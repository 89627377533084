import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsPanelTitle = _resolveComponent("SettingsPanelTitle")!
  const _component_VuiCheckbox = _resolveComponent("VuiCheckbox")!
  const _component_ItemsBox = _resolveComponent("ItemsBox")!

  return (_openBlock(), _createElementBlock("rbe-notifications-preferences", null, [
    _createVNode(_component_SettingsPanelTitle, null, {
      default: _withCtx(() => [
        _createTextVNode("\n      Preferences\n    ")
      ]),
      _: 1
    }),
    _createTextVNode(),
    _createVNode(_component_ItemsBox, { class: "filled" }, {
      default: _withCtx(() => [
        _createVNode(_component_VuiCheckbox, {
          modelValue: _ctx.isEmailEnabled,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isEmailEnabled) = $event)),
          label: "Email notifications"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}