import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0629beba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }
const _hoisted_2 = {
  key: 0,
  class: "panel-header border-bottom-dark"
}
const _hoisted_3 = { class: "panel-body" }
const _hoisted_4 = {
  key: 1,
  class: "panel-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-panel", _hoisted_1, [
    (_ctx.hasTitle)
      ? (_openBlock(), _createElementBlock("rbe-panel-header", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
          ], true)
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(),
    _createElementVNode("rbe-panel-body", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createTextVNode(),
    (_ctx.hasFooter)
      ? (_openBlock(), _createElementBlock("rbe-panel-footer", _hoisted_4, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}