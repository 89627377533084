import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    confirm: {
      label: 'Reload',
      handler: _ctx.reload,
    },
    title: "Update available",
    class: "accent-secondary"
  }, {
    default: _withCtx(() => [
      _createTextVNode("\n    We recently made changes to Reedsy Studio. Please reload\n    your browser to update to the latest version and continue working\n    on your book. Don’t worry, any recent changes have been saved 😎\n  ")
    ]),
    _: 1
  }, 8, ["id", "confirm"]))
}