import {VuexModule, Action} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {ISingleUseBookShareDb} from '@reedsy/studio.home.bookshelf/utils/single-use-book-sharedb/i-single-use-book-sharedb';
import {UserBookSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-book-settings';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {getUserBookId} from '@reedsy/reedsy-sharedb/lib/utils/get-user-book-id';
import IApi from '@reedsy/studio.shared/services/api/i-api';

@injectable()
export class BookActionsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,

    @$inject('SingleUseBookShareDb')
    singleUseBookShareDb: ISingleUseBookShareDb,

    @$inject('StoreModule')
    @named(StoreName.BookDetails)
    bookDetails: BookDetailsModule,

    @$inject('StoreModule')
    @named(StoreName.UserBookSettings)
    userBookSettings: UserBookSettingsModule,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    user: SharedUserModule,
  ) {
    @Module({name: StoreName.BookActions, store})
    class BookActions extends VuexModule {
      @Action
      public async archiveBook(bookId: string): Promise<void> {
        const bookUuid = bookDetails.bookUuid(bookId);
        await api.patchBookDetails(bookUuid, {hidden: true});
      }

      @Action
      public async unarchiveBook(bookId: string): Promise<void> {
        const bookUuid = bookDetails.bookUuid(bookId);
        await api.patchBookDetails(bookUuid, {hidden: false});
      }

      @Action
      public async changeUserGoalNotification(
        {goalId, bookId, notifications}: {goalId: string; bookId: string; notifications: boolean},
      ): Promise<void> {
        const bookUuid = bookDetails.bookUuid(bookId);
        await singleUseBookShareDb.useDoc({
          collection: 'userWordCountGoals',
          bookUuid,
          docId: goalId,
          callback: async (doc) => {
            await doc.fetch();

            await doc.mutate((data) => {
              data.notifications = notifications;
            });
          },
        });
      }

      @Action
      public async toggleCheckInNotifications(
        {bookId, enable}: {bookId: string; enable: boolean},
      ): Promise<void> {
        const bookSettings = userBookSettings.forBook(bookId);
        const bookUuid = bookDetails.bookUuid(bookId);

        await singleUseBookShareDb.useDoc({
          collection: 'userBookSettings',
          bookUuid,
          docId: bookSettings._id,
          callback: async (doc) => {
            await doc.fetch();

            await doc.mutate((data) => {
              data.notifications.checkIn = enable;
            });
          },
        });
      }

      @Action
      public async ensureUserBookSettingsCreated(bookId: string): Promise<void> {
        const bookUuid = bookDetails.bookUuid(bookId);

        await singleUseBookShareDb.useDoc({
          collection: 'userBookSettings',
          bookUuid,
          docId: getUserBookId({userId: user.id, bookId}),
          callback: async (doc) => {
            await doc.fetch();
          },
        });
      }
    }
    this.Module = BookActions;
  }
}

export type BookActionsModule = InstanceType<BookActionsModuleFactory['Module']>;
