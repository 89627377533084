<template>
  <EntryAction class="entry-action">
    <router-link
      ref="manageButton"
      class="
        button
        button-text
        button-md
        button-black
        manage-button
      "
      :to="bookLocation"
      :aria-label="`Manage book ${book.title}`"
    >
      Manage
      <VuiIconSettings />
    </router-link>
    <a
      class="
        button
        button-text
        button-md
        full-width
        button-accent
        open-in-editor-button
      "
      :href="bookEditorLink"
      target="_blank"
      :aria-label="`Continue writing ${book.title}`"
    >
      Write
      <VuiIconArrowRight />

    </a>
  </EntryAction>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import EntryAction from './entry-action.vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {encodeUUID} from '@reedsy/utils.uuid';
import {IMetaLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({
  components: {
    EntryAction,
  },
})
export default class BookActions extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @Prop({type: Object, required: true})
  public book: IBookshelfBook;

  public get bookLocation(): IMetaLocation {
    return {
      name: BookshelfRouteName.Book,
      params: {
        bookUuid: encodeUUID(this.book.uuid),
      },
    };
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }
}
</script>

<style lang="scss" scoped>
$space-from-edge-in-percentage: #{100 * math.div($space-sm, $lg-cover-width)}#{'%'};

.entry-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;
  padding: $space-md;
  width: calc(100% - $cover-placeholder-spine-width);
  margin-left: $cover-placeholder-spine-width;

  .button {
    display: flex;
    justify-content: space-between;

    :deep(.vui-icon) {
      #icon-fill {
        fill: color($white);
      }
    }
  }

  .manage-button {
    margin-bottom: $space-sm;
  }
}
</style>
