<template>
  <rbe-book-import-entry class="slide-entry">
    <rbe-book-import-content>
      <rbe-book-cover-loader>
        <VuiLoadingIndicator class="lg" />
        <p>Importing</p>
      </rbe-book-cover-loader>
    </rbe-book-import-content>
    <rbe-book-title>
      <p
        class="ellipsis"
      >
        {{ title }}
      </p>
    </rbe-book-title>
  </rbe-book-import-entry>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import NoBookCoverPlaceholder from '@reedsy/studio.home.bookshelf/components/book-cover/no-book-cover-placeholder.vue';
import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';

@Component({
  components: {
    NoBookCoverPlaceholder,
  },
})
export default class BookImportEntry extends BookshelfVue {
  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry;

  public get bookImport(): IBookImport {
    return this.entry.item as IBookImport;
  }

  public get title(): string {
    return this.bookImport.importData.title;
  }
}
</script>

<style lang="scss" scoped>
rbe-book-import-entry {
  rbe-book-import-content {
    display: grid;
    place-items: center;
    height: 100%;
    background-color: var(--text-color);
    border-radius: $border-radius-lg;
  }

  rbe-book-cover-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-md;

    p {
      color: $cover-placeholder-text-color;
    }
  }
}
</style>
