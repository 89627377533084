import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-644ee81f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-top" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!

  return (_openBlock(), _createElementBlock("rbe-feedback-field", null, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
    ]),
    _createTextVNode(),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createTextVNode(),
    _createVNode(_component_ExpandTransition, null, {
      default: _withCtx(() => [
        (_ctx.showExtraField)
          ? (_openBlock(), _createElementBlock("rbe-extra-field-wrapper", _hoisted_2, [
              _renderSlot(_ctx.$slots, "extra-field", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}