import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b788d812"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("rbe-info-placeholder", _hoisted_1, [
      _createVNode(_component_Avatar, {
        class: "avatar-placeholder",
        placeholder: ""
      }),
      _createTextVNode(),
      _createVNode(_component_SkeletonLoader, { class: "loader loader-name" })
    ]),
    _createTextVNode(),
    _createVNode(_component_SkeletonLoader, { class: "loader loader-text" }),
    _createTextVNode(),
    _createVNode(_component_SkeletonLoader, { class: "loader loader-menu" })
  ], 64))
}