import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {injectable} from 'inversify';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import ShareDb from '@reedsy/studio.shared/services/sharedb/sharedb';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {IBookshelfCollections} from './i-bookshelf-sharedb';
import {IBookshelfBookShareDbConnection} from './bookshelf-book-sharedb-connection.interface';

@injectable()
export class BookshelfBookRedirectingShareDb extends ShareDb<IBookshelfCollections, IBookshelfBookShareDbConnection> {
  public readonly navigation: INavigation;

  public constructor(
  @$inject('LoggerFactory') loggerFactory: LoggerFactory,
    @$inject('Navigation') navigation: INavigation,
    @$inject('BookshelfBookShareDbConnection') shareDbConnection: IBookshelfBookShareDbConnection,
  ) {
    super(loggerFactory, shareDbConnection);
    this.navigation = navigation;
  }

  protected async handleUnauthorized(error: any, data: any): Promise<void> {
    this.logger.debug(error, {data});
    await this.navigation.goToBookShelf();
  }
}
