import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {Store} from 'vuex';
import {IBookshelfBookShareDb} from '@reedsy/studio.home.bookshelf/services/i-bookshelf-sharedb';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {clone} from '@reedsy/utils.clone';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import {IBookExportData} from '@reedsy/reedsy-sharedb/lib/common/book/book-export-data';
import {Doc} from 'sharedb/lib/client';
import {IBookshelfBookModule} from '@reedsy/studio.home.bookshelf/store/helpers/i-bookshelf-book-module';
import {memoize} from '@reedsy/utils.object';
import {IInitBookData} from '@reedsy/studio.home.bookshelf/store/helpers/i-book-data-store-coordinator';
import {config} from '@reedsy/studio.shared/config';
import {ExportState} from '@reedsy/reedsy-sharedb/lib/common/book-export/export-state';
import {compare} from '@reedsy/utils.sort';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

// eslint-disable-next-line
export default function bookExportsDataModuleFactory({bookUuid}: IInitBookData, bookShareDb: IBookshelfBookShareDb, store: Store<any>) {
  const name = `bookshelf_bookExports_${bookUuid}`;

  @Module({name, store})
  class BookExportsDataModule extends VuexModule implements IBookshelfBookModule {
    private bookExports: {[exportId: string]: IBookExport} = {};

    public get data() {
      return (exportId: string): IBookExportData => {
        const bookExport = this.bookExports[exportId];
        if (!bookExport) return null;
        return bookExport.exportData;
      };
    }

    public get allExports(): IBookExport[] {
      return Object.entries(this.bookExports)
        .map(([exportId, bookExport]) => {
          return {
            _id: exportId,
            ...bookExport,
          };
        })
        .sort((export1, export2) => {
          return compare(
            export1.exportData.createdAt,
            export2.exportData.createdAt,
            {descending: true},
          );
        });
    }

    public get downloadExportLink() {
      return (exportId: string): string => {
        const bookExportData = this.data(exportId);
        if (!bookExportData) return '';
        if (bookExportData.state !== ExportState.Processed) return '';

        return `${config.cdn.workerHost}/${bookExportData.exportUrl}`;
      };
    }

    @Mutation
    public DATA({id, data}: IDataMutation<'bookExports'>): void {
      this.bookExports[id] = clone(data);
    }

    @Mutation
    public APPLY_OPS({id, ops}: IApplyOpsMutation<'bookExports'>): void {
      ops.forEach((op) => applyOp(this.bookExports[id], op));
    }

    @Action
    @memoize
    public async initialise(): Promise<void> {
      const query = await bookShareDb.subscribeQuery('bookExports', {
        'exportData.state': {$ne: ExportState.Cancelled},
      });

      const subscribeDocs = (docs: Doc<IBookExport>[]): void => {
        docs.forEach((doc) => subscribeVuexToDoc(doc, this));
      };

      subscribeDocs(query.results);
      query.on('insert', subscribeDocs);
      query.on('remove', (removedDocs) => {
        removedDocs.forEach((doc) => {
          const exportId = doc.id;
          this.REMOVE_BOOK_EXPORT(exportId);
          doc.destroy();
        });
      });
    }

    @Mutation
    private REMOVE_BOOK_EXPORT(exportId: string): void {
      delete this.bookExports[exportId];
    }
  }

  return BookExportsDataModule;
}

export type BookExportsDataModule = InstanceType<ReturnType<typeof bookExportsDataModuleFactory>>;
