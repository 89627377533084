import {injectable, named} from 'inversify';
import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {ISettings} from '@reedsy/reedsy-sharedb/lib/common/user-settings/settings';
import {$inject} from '@reedsy/studio.shared/types';
import {IFeedbackResponse} from '@reedsy/reedsy-sharedb/lib/common/feedback/feedback-response';
import {GoogleAnalyticsEvent} from '@reedsy/utils.analytics';

@injectable()
export abstract class TenHoursSurveyBasePluginFactory extends RouterPlugin {
  protected abstract get userSettings(): ISettings;
  protected abstract skipSurvey(): Promise<void>;
  protected abstract submitFeedback(feedback: IFeedbackResponse): Promise<void>;

  @$inject('StoreModule')
  @named(SharedStoreName.User)
  public readonly $user: SharedUserModule;

  @$inject('StoreModule')
  @named(SharedStoreName.Modals)
  public readonly $modals: SharedModalsModule;

  public override async afterEach(): Promise<void> {
    if (!this.userSettings) return;
    if (this.userSettings.feedbackAfterTenHours !== undefined) return;
    if (!this.$user.events[GoogleAnalyticsEvent.TotalSessionsGte10h]) return;
    if (this.$modals.hasOpenModals) return;

    await this.$modals.OPEN({
      type: 'FeedbackSurvey',
      context: {
        skipSurvey: this.skipSurvey.bind(this),
        submitFeedback: this.submitFeedback.bind(this),
      },
    });
  }
}
