import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-43ca313f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_VuiIconWarning = _resolveComponent("VuiIconWarning")!

  return (_openBlock(), _createElementBlock("rbe-export-type-indicator", {
    class: _normalizeClass({
      loading: _ctx.isPending,
      error: _ctx.isFailed
    })
  }, [
    _createElementVNode("img", {
      src: _ctx.imageSrc,
      alt: _ctx.exportType
    }, null, 8, _hoisted_1),
    _createTextVNode(),
    _createElementVNode("rbe-indicator-overlay", null, [
      (_ctx.isPending)
        ? (_openBlock(), _createBlock(_component_VuiLoadingIndicator, {
            key: 0,
            class: "color-accent-alt"
          }))
        : (_openBlock(), _createBlock(_component_VuiIconWarning, {
            key: 1,
            class: "icon-xl warning-icon"
          }))
    ])
  ], 2))
}