import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-17d38faf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AcceptInvitationPanel = _resolveComponent("AcceptInvitationPanel")!

  return (_openBlock(), _createElementBlock("rbe-invitation-acceptance-view", _hoisted_1, [
    _createVNode(_component_AcceptInvitationPanel)
  ]))
}