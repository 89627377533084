import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';
import {IBookImportData} from '@reedsy/reedsy-sharedb/lib/common/book/book-import-data';
import {clone} from '@reedsy/utils.clone';
import {leftMinusRight} from '@reedsy/utils.set';
import {memoize} from '@reedsy/utils.object';
import {IDbSnapshot} from '@reedsy/studio.isomorphic/utils/i-db-snapshot';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {Op} from 'sharedb';
import {ImportState} from '@reedsy/reedsy-sharedb/lib/common/book-import/import-state';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {compare} from '@reedsy/utils.sort';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {IImportRequest} from '@reedsy/studio.shared/services/api/i-import-data';
import {entries} from '@reedsy/utils.iterable';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';

@injectable()
export class BooksImportsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.ShareDb)
    shareDb: ShareDBModule,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: StoreName.BooksImports, store})
    class BookDetails extends VuexModule {
      private booksImports: {[importId: string]: IBookImport} = {};

      public get data() {
        return (importId: string): IBookImportData => {
          const bookImport = this.booksImports[importId];
          if (!bookImport) return null;
          return bookImport.importData;
        };
      }

      public get allImports(): IBookImport[] {
        return entries(this.booksImports)
          .map(([importId, importData]) => {
            return {
              _id: importId,
              ...importData,
            };
          })
          .sort(
            (a, b) => compare(a.importData.createdAt, b.importData.createdAt, {descending: true}),
          );
      }

      public get importByBookId(): ImportsByBookId {
        return this.allImports
          .filter((bookImport) => !!bookImport.importData.bookId)
          .reduce(
            (importsByBookId, bookImport) => {
              importsByBookId[bookImport.importData.bookId] = bookImport;
              return importsByBookId;
            },
            {} as ImportsByBookId,
          );
      }

      public get importDataByBookId(): ImportsDataByBookId {
        return entries(this.importByBookId)
          .reduce(
            (importsDataByBookId, [bookId, bookImport]) => {
              importsDataByBookId[bookId] = bookImport.importData;
              return importsDataByBookId;
            },
            {} as ImportsDataByBookId,
          );
      }

      private get importsIds(): string[] {
        return Object.keys(this.booksImports);
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        await this.subscribeToBooksImports();
      }

      @Mutation
      public _BOOK_IMPORT_DATA({importId, data}: {importId: string; data: IBookImport}): void {
        this.booksImports[importId] = clone(data);
      }

      @Action
      public async importBook(request: IImportRequest): Promise<{importId: string}> {
        const {title, subtitle, importFile, options} = request;
        const {importId} = await api.importBook({
          title: title,
          subtitle: subtitle,
          importFile: importFile,
          options,
        });
        return {importId};
      }

      @Action
      @NotifyError('Error occurred when trying to cancel book import.')
      public async cancelBookImport(importId: string): Promise<void> {
        await api.cancelBookImport(importId);
      }

      @Action
      private async subscribeToBooksImports(): Promise<void> {
        const query = await shareDb.subscribeQuery({
          collection: 'bookImports',
          query: {
            $aggregate: [
              {$match: {'importData.state': {$ne: ImportState.Cancelled}}},
            ],
          },
        });

        const subscribeToBookImport = async (bookImport: IDbSnapshot<IBookImport>): Promise<void> => {
          const importId = bookImport._id;
          const doc = await shareDb.subscribe({
            collection: 'bookImports',
            id: importId,
          });
          this._BOOK_IMPORT_DATA({importId, data: doc.data});

          doc.on('op batch', (ops: any) => this.applyOpsAndNotifyWhenImportFailed({importId, ops}));
          doc.on('load', () => this._BOOK_IMPORT_DATA({importId, data: doc.data}));
          doc.on('del', () => this.REMOVE_BOOK_IMPORT_DATA(importId));
        };

        const updateExtra = async (bookImports: IBookImport[]): Promise<void> => {
          const currentIds = bookImports.map((bookImport) => bookImport._id);
          const idsToRemove = leftMinusRight(new Set(this.importsIds), new Set(currentIds));
          await Promise.all(bookImports.map(subscribeToBookImport));

          idsToRemove.forEach((bookImportId) => {
            this.REMOVE_BOOK_IMPORT_DATA(bookImportId);
            const doc = shareDb.get('bookImports', bookImportId);
            doc.destroy();
          });
        };

        query.on('extra', updateExtra);
        await updateExtra(query.extra);
      }

      @Action
      private applyOpsAndNotifyWhenImportFailed({importId, ops}: {importId: string; ops: Op[]}): void {
        const bookImportData = this.data(importId);
        const previousState = bookImportData.state;

        this.APPLY_OPS_TO_BOOK_IMPORT_DATA({importId, ops});
        const newState = this.data(importId).state;

        const hasStateChangeToFailed = previousState === ImportState.Pending && newState === ImportState.Failed;

        if (!hasStateChangeToFailed) return;

        Notify.error({
          message: 'Something went wrong with your import, if the problem persists, please contact our support team.',
        });
      }

      @Mutation
      private REMOVE_BOOK_IMPORT_DATA(importId: string): void {
        delete this.booksImports[importId];
      }

      @Mutation
      private APPLY_OPS_TO_BOOK_IMPORT_DATA({importId, ops}: {importId: string; ops: Op[]}): void {
        ops.forEach((op) => applyOp(this.booksImports[importId], op));
      }
    }
    this.Module = BookDetails;
  }
}

export type BooksImportsModule = InstanceType<BooksImportsModuleFactory['Module']>;
export type ImportsByBookId = {
  [bookId: string]: IBookImport;
};
export type ImportsDataByBookId = {
  [bookId: string]: IBookImportData;
};
