
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import ExportThemePreview from './export-theme-preview.vue';
import TabOptions from '@reedsy/studio.home.bookshelf/components/tab-options/tab-options.vue';
import {Theme} from '@reedsy/schemas.workers.book-export';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import PanelBodyTitle from '@reedsy/studio.home.bookshelf/components/panel/panel-body-title.vue';

@Component({
  components: {
    Panel,
    ExportThemePreview,
    TabOptions,
    PanelBodyTitle,
  },
})
export default class ThemeSelector extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public readonly themeOptions = [
    {
      description: 'Merriweather',
      image: require('@/assets/inlined/theme/1.svg'),
      title: 'Reedsy',
      value: Theme.Reedsy,
    },
    {
      description: 'Crimson',
      image: require('@/assets/inlined/theme/2.svg'),
      title: 'Classic',
      value: Theme.Classic,
    },
    {
      description: 'Crimson',
      image: require('@/assets/inlined/theme/3.svg'),
      title: 'Romance',
      value: Theme.Romance,
    },
  ];

  public get theme(): Theme {
    return this.$bookExportSubmission.theme;
  }

  public set theme(theme: Theme) {
    this.$bookExportSubmission.THEME(theme);
  }
}
