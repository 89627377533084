<template>
  <rbe-subscription-price>
    {{ price }}/month
  </rbe-subscription-price>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from './subscription-base.vue';
import {PropType} from 'vue';
import {IPriceCurrencyOptionsResponse, IPriceCurrencyOption} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-currency-options-response';
import {localCurrency} from '@reedsy/studio.shared/services/stripe/local-currency';
import {choosePrice} from '@reedsy/studio.shared/services/stripe/choose-price';

@Component({
  components: {
    SubscriptionBaseModal,
  },
})
export default class SubscriptionPrice extends ClientSharedVue {
  @Prop({required: true, type: Object as PropType<IPriceCurrencyOptionsResponse>})
  public currencies: IPriceCurrencyOptionsResponse;

  private readonly currency = localCurrency();

  public get price(): string {
    const {currency, price} = this.priceOption;
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency,
    });
  }

  private get priceOption(): IPriceCurrencyOption {
    // TODO: [subscriptions] turn into price picker component
    return choosePrice(this.currency, this.currencies.outlining.month);
  }
}
</script>

<style lang="scss" scoped>
rbe-subscription-price {
  @include font-family($controls, heavy);

  font-size: $font-size-xxxl;
}
</style>
