<template>
  <rbe-loading v-if="!currencies">
    <DelayedLoadingIndicator :delay="1_000" />
  </rbe-loading>
  <SubscriptionBaseModal
    v-else
    :id="id"
    title="Ready to go premium?"
    class="subscription-trial-modal"
  >
    <template #left>
      <p>
        Features to help you write your book.
        Unlock boards and build your writing routine.
      </p>

      <SubscriptionPrice :currencies="currencies" />

      <ul>
        <li
          v-for="({text}, index) in checklistItems"
          :key="index"
          :class="{
            active: index === activeCheckListIndex,
          }"
        >
          <button
            class="button-full"
            @click="activeCheckListIndex = index"
          >
            <VuiCheckbox
              :label="text"
              :readonly="true"
              :value="true"
            />
          </button>
        </li>
      </ul>

      <LoadingButton
        class="button-text button-accent"
        type="button"
        :loading="loading"
        @click="startTrial"
      >
        Start {{ config.stripe.trialDays }} day trial
      </LoadingButton>

      <button
        class="button-subtle"
        type="button"
        @click="close"
      >
        No thanks, not yet!
      </button>
    </template>

    <template #right>
      TODO: [subscriptions] Add videos
    </template>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import SubscriptionBaseModal from './subscription-base.vue';
import SubscriptionPrice from './subscription-price.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {DisposableFunction, finallyCall} from '@reedsy/studio.shared/utils/finally-call/finally-call';
import {config} from '@reedsy/studio.shared/config';
import {IPriceCurrencyOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-currency-options-response';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';

@Component({
  components: {
    DelayedLoadingIndicator,
    LoadingButton,
    SubscriptionBaseModal,
    SubscriptionPrice,
  },
})
export default class SubscriptionTrial extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public readonly config = config;
  public readonly cancelable = true;

  // TODO: [subscriptions] confirm this list
  public readonly checklistItems = Object.freeze([
    {
      text: 'Unlimited notes and boards',
    },
    {
      text: 'Timeline view and relationship',
    },
    {
      text: 'Advanced writing statistics',
    },
    {
      text: 'Goal tracking & check-ins',
    },
  ]);

  public activeCheckListIndex = 0;
  public loading = false;
  public currencies: IPriceCurrencyOptionsResponse = null;

  public async created(): Promise<void> {
    this.currencies = await this.$subscription.fetchPrice();
  }

  public async startTrial(): Promise<void> {
    using doneLoading = this.startLoading();
    await this.$subscription.startTrial();
    doneLoading();
    this.close();
    // TODO: [subscriptions] open confirmation modal
  }

  private startLoading(): DisposableFunction<() => void> {
    this.loading = true;
    return finallyCall(() => this.loading = false);
  }
}
</script>

<style lang="scss" scoped>
.subscription-trial-modal {
  ul {
    display: grid;
    gap: $space-md;
    padding: 0;
    margin: $space-md 0;
  }

  li {
    --item-background: transparent;

    $active-radius: $border-radius-xl;

    position: relative;
    background: linear-gradient(to right, transparent, var(--item-background));
    margin-right: calc(-1 * var(--column-padding));
    border-top-left-radius: $active-radius;
    border-bottom-left-radius: $active-radius;

    &::before,
    &::after {
      --radius-shadow: #{$active-radius};
      --radius-shadow-height: #{2 * $active-radius};

      content: '';
      position: absolute;
      right: 0;
      width: $active-radius;
      height: var(--radius-shadow-height);
      box-shadow: 0 var(--radius-shadow) 0 0 var(--item-background);
    }

    &::before {
      top: calc(-1 * var(--radius-shadow-height));
      border-bottom-right-radius: $active-radius;
    }

    &::after {
      --radius-shadow: #{-$active-radius};

      bottom: calc(-1 * var(--radius-shadow-height));
      border-top-right-radius: $active-radius;
    }

    &.active {
      --item-background: var(--selection-background);
    }

    button {
      display: flex;
      padding: $space-sm;
      justify-content: left;
    }

    :deep(label) {
      cursor: pointer;
    }
  }

  .button-subtle {
    justify-self: left;
    margin-top: $space-sm;
  }

  // TODO: Get mobile/tablet designs
  @include screen-less-than(md) {
    li {
      &.active {
        --item-background: transparent;
      }

      button,
      :deep(label) {
        cursor: default;
      }
    }
  }
}
</style>
