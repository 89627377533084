
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import TabOptions from '@reedsy/studio.home.bookshelf/components/tab-options/tab-options.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

@Component({
  components: {
    Panel,
    TabOptions,
  },
})
export default class ExportTypeSelector extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public readonly exportOptions = [
    {
      description: '',
      image: require('@/assets/inlined/export-type/pdf.svg'),
      title: 'Print-ready PDF',
      value: ExportType.Pdf,
    },
    {
      description: '',
      image: require('@/assets/inlined/export-type/epub.svg'),
      title: 'EPUB',
      value: ExportType.EPub,
    },
  ] as const;

  public get exportType(): ExportType {
    return this.$bookExportSubmission.exportType;
  }

  public set exportType(exportType: ExportType) {
    this.$bookExportSubmission.EXPORT_TYPE(exportType);
  }
}
