import {Store} from 'vuex';
import {injectable} from 'inversify';
import {BookDataModuleFactory, BookDataStore} from './book-data-store';
import {BOOKSHELF_BOOK_DATA_STORE_FACTORY_MAP} from '@reedsy/studio.home.bookshelf/store/bookshelf-book-data-store-factory-map';
import {DataStoreFactoryMap, DataStoreMap, DataStoreModule, IBookDataStoreCoordinator, IInitBookData} from './i-book-data-store-coordinator';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {objectKeys} from '@reedsy/utils.object';
import {IBookshelfBookShareDbManager} from '@reedsy/studio.home.bookshelf/services/i-bookshelf-book-sharedb-manager';

@injectable()
export class BookDataStoreCoordinator implements IBookDataStoreCoordinator {
  private readonly modules: DataStoreMap;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('BookshelfBookShareDbManager')
    private readonly bookShareDbManager: IBookshelfBookShareDbManager,
  ) {
    this.modules = {} as any;
    objectKeys(BOOKSHELF_BOOK_DATA_STORE_FACTORY_MAP)
      .forEach(<K extends keyof DataStoreFactoryMap>(key: K) => {
        const factory = BOOKSHELF_BOOK_DATA_STORE_FACTORY_MAP[key] as BookDataModuleFactory<DataStoreModule<K>>;

        this.modules[key] = new BookDataStore(
          store,
          factory,
          this.bookShareDbManager,
        ) as DataStoreMap[K];
      });
  }

  public getModule<T extends keyof DataStoreMap>(moduleName: T): DataStoreMap[T]['current'] {
    return this.modules[moduleName].current;
  }

  public async initialiseBookData(initBookData: IInitBookData): Promise<void> {
    Object
      .values(this.modules)
      .forEach((dataStore: BookDataStore<any>) => dataStore.createModule(initBookData));

    await Promise.all(
      Object
        .values(this.modules)
        .map(async (dataStore) => await dataStore.current.initialise()),
    );
  }

  public async removeBookData(): Promise<void> {
    Object.values(this.modules).forEach((dataStore: BookDataStore<any>) => {
      dataStore.removeModule();
    });
  }
}
