export default async function cancelableModals(
  modals: Record<string, () => Promise<{default: any}>>,
): Promise<Record<string, boolean>> {
  const cancelable: Record<string, boolean> = {};

  for (const [type, importer] of Object.entries(modals)) {
    const imported = await importer();
    const Component = imported.default;
    cancelable[type] = !!Component.data().cancelable;
  }

  return cancelable;
}
