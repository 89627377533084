<template>
  <div class="empty-list-placeholder">
    <img
      src="@/assets/inlined/open-book.svg"
      alt="no exports"
    >
    <div class="under-image">
      <p class="title">
        No exports yet.
      </p>
      <p>Your export will appear here.</p>
      <router-link
        ref="new-export-button"
        :to="bookExportSubmissionRoute"
        class="button button-text button-accent new-export-button"
      >
        New export
        <VuiIconPlus class="icon-sm new-export-icon" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';
import NewExportButton from '@reedsy/studio.home.bookshelf/components/exports-page-actions/new-export-button.vue';

@Component({
  components: {
    NewExportButton,
  },
})
export default class EmptyListPlaceholder extends BookshelfVue {
  public get bookExportSubmissionRoute(): MetaRawLocation {
    return {
      name: BookshelfRouteName.BookExportSubmission,
    };
  }
}
</script>

<style lang="scss" scoped>
.empty-list-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include screen-more-than(sm) {
    min-height: 30rem;
  }

  .under-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p.title {
      margin-top: $space-md;
      font-size: $font-size-lg;

      @include font-family($controls, bold);
    }

    .new-export-button {
      margin-top: $space-md;

      .new-export-icon {
        margin-left: $space-sm;
      }
    }
  }
}
</style>
