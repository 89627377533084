import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-head" }
const _hoisted_2 = { class: "ellipsis" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "modal-footer"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconCross = _resolveComponent("VuiIconCross")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_VForm = _resolveComponent("VForm")!

  return (_openBlock(), _createBlock(_component_VForm, {
    ref: "form",
    class: "modal reedsy-accented",
    disabled: _ctx.confirming || null,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createTextVNode(),
        (_ctx.cancelable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "button-icon button-sm close-modal-button reedsy-accented",
              type: "button",
              disabled: _ctx.confirming || null,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, [
              _createVNode(_component_VuiIconCross, { class: "base-icon" })
            ], 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createTextVNode(),
      _createElementVNode("rbe-modal-content", null, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createTextVNode(),
      (_ctx.confirm || _ctx.cancelable)
        ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
            (_ctx.hasExtraActions)
              ? (_openBlock(), _createElementBlock("rbe-modal-actions", _hoisted_5, [
                  _renderSlot(_ctx.$slots, "extra-actions")
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(),
            (_ctx.cancelable)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  ref: "cancel",
                  class: "button-text cancel-button",
                  type: "button",
                  disabled: _ctx.confirming || _ctx.cancelDisabled || null,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }, _toDisplayString(_ctx.cancelLabel), 9, _hoisted_6))
              : _createCommentVNode("", true),
            _createTextVNode(),
            (_ctx.confirm)
              ? (_openBlock(), _createBlock(_component_LoadingButton, {
                  key: 2,
                  ref: "confirm",
                  type: "submit",
                  disabled: _ctx.confirming || _ctx.confirmDisabled || null,
                  loading: _ctx.confirming,
                  class: "button reedsy-accented button-text"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.confirm.label), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["disabled", "onSubmit"]))
}