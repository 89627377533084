import {injectable, named} from 'inversify';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {BookshelfStoreListener} from '@reedsy/studio.home.bookshelf/store/bookshelf-store-listener';

export interface ITitleTemplateContext {
  bookTitle?: string;
}

const DISPLAYABLE_ROUTE_NAME_PART = {
  [BookshelfRouteName.App]: 'Reedsy',

  [BookshelfRouteName.Bookshelf]: '',
  [BookshelfRouteName.BooksList]: 'Bookshelf',
  [BookshelfRouteName.ArchivedBooks]: 'Archived books',

  [BookshelfRouteName.AccountSettings]: 'Account settings',
  [BookshelfRouteName.GeneralAccountSettings]: 'General',
  [BookshelfRouteName.SubscriptionAccountSettings]: 'Subscription',

  [BookshelfRouteName.Book]: ({bookTitle}: ITitleTemplateContext) => bookTitle,
  [BookshelfRouteName.BookAccess]: 'Access',
  [BookshelfRouteName.BookExports]: 'Exports',
  [BookshelfRouteName.BookExportsList]: '',
  [BookshelfRouteName.BookExportSubmission]: '',
  [BookshelfRouteName.BookSettings]: 'Settings',

  [BookshelfRouteName.Activity]: 'Activity',

  [BookshelfRouteName.InvitationAcceptance]: 'Accept invitation',

  [BookshelfRouteName.NotFound]: 'Not found',
} as const satisfies Record<BookshelfRouteName, string | ((context: ITitleTemplateContext) => string)>;

@injectable()
export default class BrowserTitlePluginFactory extends RouterPlugin {
  @$inject('StoreListener')
  public readonly _storeListener: BookshelfStoreListener;

  @$inject('StoreModule')
  @named(StoreName.CurrentBook)
  public readonly _currentBook: CurrentBookModule;

  protected override async afterEach(): Promise<void> {
    this.updateDocumentTitle();
  }

  protected override setup(): void {
    this._storeListener.subscribe(
      StoreName.BookDetails,
      'DATA',
      this.updateDocumentTitle.bind(this),
    );
    this._storeListener.subscribe(
      StoreName.BookDetails,
      'APPLY_OPS',
      this.updateDocumentTitle.bind(this),
    );
    this._storeListener.subscribe(
      StoreName.CurrentBook,
      'UUID',
      this.updateDocumentTitle.bind(this),
    );
  }

  private updateDocumentTitle(): void {
    const templateContext: ITitleTemplateContext = {
      bookTitle: this._currentBook.data?.title || '',
    };

    document.title = this._router
      .currentRoute
      .value
      .matched
      .map((route) => route.name as BookshelfRouteName)
      .map((routeName) => DISPLAYABLE_ROUTE_NAME_PART[routeName])
      .map((partTemplate) => {
        if (typeof partTemplate === 'function') return partTemplate(templateContext);
        return partTemplate;
      })
      .filter(Boolean)
      .reverse()
      .join(' - ');
  }
}
