import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {IExportPayload} from '@reedsy/studio.isomorphic/models/export-request';
import {ExportType, NotesPosition, Theme, TrimSize} from '@reedsy/schemas.workers.book-export';
import {clone} from '@reedsy/utils.clone';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';

interface IExportDetails {
  type: ExportType;
  options: Omit<
    IExportPayload['options'],
  'subtitle' | 'title'
  >;
}

const VALID_EXPORT_TYPES_ENDNOTES_POSITIONS = Object.freeze(
  {
    [ExportType.Pdf]: [NotesPosition.EndOfPage, NotesPosition.EndOfBook],
    [ExportType.EPub]: [NotesPosition.EndOfChapter, NotesPosition.EndOfBook],
    [ExportType.Docx]: [] as NotesPosition[],
  } as const satisfies Record<ExportType, readonly NotesPosition[]>,
);

@injectable()
export class BookExportSubmissionModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.CurrentBook)
    currentBook: CurrentBookModule,

    @$inject('StoreModule')
    @named(StoreName.Modals)
    modals: BookshelfModalsModule,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: StoreName.BookExportSubmission, store})
    class BookExportSubmission extends VuexModule {
      public isSubmittingExportRequest = false;

      private exportDetails: IExportDetails = getCleanExportDetails();

      public get exportType(): ExportType {
        return this.exportDetails.type;
      }

      public get chapterNumber(): boolean {
        return this.exportDetails.options.chapterNumbering;
      }

      public get dropCaps(): boolean {
        return this.exportDetails.options.dropcaps;
      }

      public get endnotePosition(): NotesPosition {
        const userSelectedEndnotePosition = this.exportDetails.options.notesPos;

        if (!userSelectedEndnotePosition) return this.defaultEndnotePosition;
        if (!this.isUserSelectedEndnotePositionValid) return this.defaultEndnotePosition;

        return userSelectedEndnotePosition;
      }

      public get theme(): Theme {
        return this.exportDetails.options.theme;
      }

      public get trimSize(): TrimSize {
        return this.exportDetails.options.trimSize;
      }

      private get exportPayload(): IExportPayload {
        const payload: IExportPayload = {
          type: this.exportDetails.type,
          options: {
            ...this.exportDetails.options,
            cover: currentBook.data?.coverUrl,
            subtitle: currentBook.data?.subtitle,
            notesPos: this.endnotePosition,
          },
        };

        if (this.exportType !== ExportType.Pdf) {
          delete payload.options.trimSize;
        }

        return payload;
      }

      private get defaultEndnotePosition(): NotesPosition {
        if (this.exportType === ExportType.Pdf) return NotesPosition.EndOfPage;

        return NotesPosition.EndOfBook;
      }

      private get isUserSelectedEndnotePositionValid(): boolean {
        return VALID_EXPORT_TYPES_ENDNOTES_POSITIONS[this.exportType]
          .includes(this.exportDetails.options.notesPos as any);
      }

      private get docxPayload(): IExportPayload {
        const payload = clone(this.exportPayload);
        delete payload.options.trimSize;

        payload.type = ExportType.Docx;
        return payload;
      }

      @Action
      public async exportDocx(): Promise<void> {
        await this.runBookExport(this.docxPayload);
      }

      @Action
      public async startBookExport(): Promise<void> {
        await this.runBookExport(this.exportPayload);
      }

      @Action
      public async resetSelections(): Promise<void> {
        this.EXPORT_DETAILS(getCleanExportDetails());
        this.ENDNOTE_POSITION(null);
      }

      @Mutation
      public EXPORT_TYPE(value: ExportType): void {
        this.exportDetails.type = value;
      }

      @Mutation
      public CHAPTER_NUMBER(value: boolean): void {
        this.exportDetails.options.chapterNumbering = value;
      }

      @Mutation
      public DROP_CAPS(value: boolean): void {
        this.exportDetails.options.dropcaps = value;
      }

      @Mutation
      public ENDNOTE_POSITION(value: NotesPosition): void {
        this.exportDetails.options.notesPos = value;
      }

      @Mutation
      public THEME(value: Theme): void {
        this.exportDetails.options.theme = value;
      }

      @Mutation
      public TRIM_SIZE(value: TrimSize): void {
        this.exportDetails.options.trimSize = value;
      }

      @Action
      @NotifyError('There was a problem exporting your book. Please try again or contact our support team.')
      private async runBookExport(payload: IExportPayload): Promise<void> {
        try {
          this.IS_STARTING_EXPORT(true);
          await api.exportBook(currentBook.uuid, payload);
          modals.OPEN('ExportStartedModal');
        } finally {
          this.IS_STARTING_EXPORT(false);
        }
      }

      @Mutation
      private EXPORT_DETAILS(value: IExportDetails): void {
        this.exportDetails = value;
      }

      @Mutation
      private IS_STARTING_EXPORT(value: boolean): void {
        this.isSubmittingExportRequest = value;
      }
    }
    this.Module = BookExportSubmission;
  }
}

export type BookExportSubmissionModule = InstanceType<BookExportSubmissionModuleFactory['Module']>;

function getCleanExportDetails(): IExportDetails {
  return {
    type: ExportType.Pdf,
    options: {
      theme: Theme.Reedsy,
      chapterNumbering: true,
      notesPos: null,
      dropcaps: false,
      trimSize: TrimSize.Standard,
    },
  };
}
