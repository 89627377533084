<template>
  <div class="selected-file-info">
    <img src="@/assets/inlined/file-icons/doc.svg">

    <label
      class="ellipsis"
      data-test="import-file-name"
    >
      {{ importFile.name }}
    </label>

    <transition
      name="fade"
      mode="out-in"
    >
      <button
        v-if="!isUploading"
        class="select-different-file"
        title="Select a different file"
        type="button"
        data-test="different-file-button"
        @click="clearFileSelection"
      >
        Select a different file
      </button>
      <p
        v-else-if="isUploadingFinished"
        data-test="initalizing-import"
      >
        Almost there...
      </p>
      <VuiProgressBar
        v-else
        :percentage="progressPercentage"
        data-test="progress-bar"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {approximatelyEqual} from '@reedsy/studio.isomorphic/utils/approximately-equal';

@Component({
  components: {
    SelectedFileInfo,
  },
})
export default class SelectedFileInfo extends BookshelfVue {
  @Prop({type: File})
  public importFile: File;

  @Prop({type: Number})
  public progressPercentage: number;

  public get isUploading(): boolean {
    return typeof this.progressPercentage === 'number';
  }

  public get isUploadingFinished(): boolean {
    return approximatelyEqual(this.progressPercentage, 100);
  }

  @Emit('clear-file')
  public clearFileSelection(): File {
    return null;
  }
}
</script>

<style lang="scss" scoped>
.selected-file-info {
  background-color: var(--TEXT-BACKGROUND);
  border-radius: $border-radius-lg;
  border: $vui-border-width-base solid vuiGetColor($vui-grey);
  padding: 1.75rem;
  text-align: center;

  label {
    margin: $space-md 0;
  }

  img {
    height: 6.5rem;
  }

  .select-different-file {
    @include font-family($controls);

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
