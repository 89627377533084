/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {IUserInfo} from '@reedsy/schemas.editor-collections';
import {pick} from '@reedsy/utils.object';

export function displayedCurrentUser(user: IUserInfo) {
  return {
    _id: user._id?.toHexString(),
    ...pick(
      user,
      'avatarUrl',
      'firstName',
      'lastName',
      'name',
      'uuid',
      'email',
    ),
  };
}
export type IDisplayedCurrentUser = ReturnType<typeof displayedCurrentUser>;
