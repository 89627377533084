import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoBooksPlaceholder = _resolveComponent("NoBooksPlaceholder")!
  const _component_BookCreationButtons = _resolveComponent("BookCreationButtons")!

  return (_openBlock(), _createElementBlock("rbe-books-grid-wrapper", null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.isEmpty)
          ? (_openBlock(), _createBlock(_component_NoBooksPlaceholder, { key: 0 }))
          : (_openBlock(), _createElementBlock("rbe-books-grid", _hoisted_1, [
              _createVNode(_component_BookCreationButtons, { class: "grid-item" }),
              _createTextVNode(),
              _renderSlot(_ctx.$slots, "default")
            ]))
      ]),
      _: 3
    })
  ]))
}