
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {InviteCollaboratorMixin} from '@reedsy/studio.shared/mixins/collaboration/invite-collaborator-mixin';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import Navigation from '@reedsy/studio.shared/services/navigation';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import ErrorMessageBox from '@reedsy/studio.shared/components/error-message-box/error-message-box.vue';
import UsersSelector from '@reedsy/studio.shared/components/users-selector/users-selector.vue';

@Component({
  components: {
    Panel,
    LoadingButton,
    ErrorMessageBox,
    UsersSelector,
  },
})
export default class InviteCollaboratorsPanel extends mixins(InviteCollaboratorMixin, BookshelfVue) {
  @$lazyInject('Navigation')
  public readonly navigation: Navigation;

  @$lazyInjectStore(StoreName.CurrentBook)
  public readonly book: CurrentBookModule;

  public async sendInvitationsToCurrentBook(): Promise<void> {
    await this.sendInvitations(this.book.id);
  }
}
