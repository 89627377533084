import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-840c4052"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportTypeSelector = _resolveComponent("ExportTypeSelector")!
  const _component_SubmitPanel = _resolveComponent("SubmitPanel")!
  const _component_BookFormattingSelector = _resolveComponent("BookFormattingSelector")!
  const _component_TrimSizeSelector = _resolveComponent("TrimSizeSelector")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!
  const _component_ThemeSelector = _resolveComponent("ThemeSelector")!
  const _component_LayoutWithRightSideBar = _resolveComponent("LayoutWithRightSideBar")!
  const _component_BookBackup = _resolveComponent("BookBackup")!

  return (_openBlock(), _createElementBlock("rbe-export-submission-creator", null, [
    _createVNode(_component_LayoutWithRightSideBar, null, {
      "left-side": _withCtx(() => [
        _createVNode(_component_ExportTypeSelector, { class: "export-creation-panel" }),
        _createTextVNode(),
        _createVNode(_component_SubmitPanel, { class: "export-creation-panel visible-until-sm" }),
        _createTextVNode(),
        _createVNode(_component_BookFormattingSelector, { class: "export-creation-panel" }),
        _createTextVNode(),
        _createVNode(_component_ExpandTransition, null, {
          default: _withCtx(() => [
            (_ctx.isPdfExportType)
              ? (_openBlock(), _createElementBlock("rbe-panel-wrapper", _hoisted_1, [
                  _createVNode(_component_TrimSizeSelector, {
                    class: "trim-size-selector",
                    "data-test": "trim-size-selector"
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createTextVNode(),
        _createVNode(_component_ThemeSelector, { class: "export-creation-panel" })
      ]),
      "right-side": _withCtx(() => [
        _createVNode(_component_SubmitPanel, { class: "right-side-panel" })
      ]),
      _: 1
    }),
    _createTextVNode(),
    _createVNode(_component_BookBackup, { class: "export-creation-panel" })
  ]))
}