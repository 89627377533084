import {injectable} from 'inversify';
import {IBookshelfBookShareDbManager, IShareDbLease} from './i-bookshelf-book-sharedb-manager';
import {IBookshelfBookShareDb} from './i-bookshelf-sharedb';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import * as uuid from 'uuid';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {Logger} from '@reedsy/reedsy-logger-js';

@injectable()
export class BookshelfBookShareDbManager implements IBookshelfBookShareDbManager {
  @$inject('BookshelfBookShareDbFactory')
  public readonly _bookshelfBookShareDbFactory: () => IBookshelfBookShareDb;

  private logger: Logger;
  private shareDbConnections: {[bookUuid: string]: IBookshelfBookShareDb} = {};
  private connectionLeaseIds: {[bookUuid: string]: Set<string>} = {};

  public constructor(
  @$inject('LoggerFactory')
    loggerFactory: LoggerFactory,
  ) {
    this.logger = loggerFactory.create('BookshelfBookShareDbManager');
  }

  public leaseConnection(bookUuid: string): IShareDbLease {
    this.shareDbConnections[bookUuid] ||= this._bookshelfBookShareDbFactory();
    this.connectionLeaseIds[bookUuid] ||= new Set<string>();

    this.initialiseConnection(bookUuid);
    const leaseId = uuid.v4();

    this.connectionLeaseIds[bookUuid].add(leaseId);

    return {
      shareDb: this.shareDbConnections[bookUuid],
      leaseId,
    };
  }

  public freeConnectionLease(leaseId: string): void {
    const bookUuid = this.getBookUuidFromLeaseId(leaseId);

    if (!bookUuid) {
      /**
       * This shouldn't happen, but in case it does we will be notified
       */
      this.logger.error('Trying to free connection lease, but connection not found', {
        data: {leaseId},
      });
      return;
    }

    this.connectionLeaseIds[bookUuid].delete(leaseId);

    if (this.connectionLeaseIds[bookUuid].size > 0) return;

    this.shareDbConnections[bookUuid].shareDbConnection.closeConnection();
    delete this.shareDbConnections[bookUuid];
    delete this.connectionLeaseIds[bookUuid];
  }

  private getBookUuidFromLeaseId(leaseId: string): string {
    for (const bookUuid in this.connectionLeaseIds) {
      if (this.connectionLeaseIds[bookUuid].has(leaseId)) {
        return bookUuid;
      }
    }

    return null;
  }

  private initialiseConnection(bookUuid: string): void {
    const connection = this.shareDbConnections[bookUuid].shareDbConnection;
    connection.init(bookUuid);
    connection.doneInitialLoad();
  }
}
