import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8dc37020"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCover = _resolveComponent("BookCover")!
  const _component_BookTitle = _resolveComponent("BookTitle")!
  const _component_ShareIndicator = _resolveComponent("ShareIndicator")!
  const _component_BookProgress = _resolveComponent("BookProgress")!
  const _component_EntryAction = _resolveComponent("EntryAction")!
  const _component_BookEntryActions = _resolveComponent("BookEntryActions")!
  const _component_BookshelfEntry = _resolveComponent("BookshelfEntry")!

  return (_openBlock(), _createBlock(_component_BookshelfEntry, {
    class: _normalizeClass(["bookshelf-entry", _ctx.coverImageClass])
  }, {
    bottom: _withCtx(() => [
      (_ctx.hasGoalSet)
        ? (_openBlock(), _createBlock(_component_EntryAction, {
            key: 0,
            class: _normalizeClass(["blurred-background", _ctx.coverImageClass])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BookProgress, {
                book: _ctx.book,
                class: "book-entry-progress"
              }, null, 8, ["book"])
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true)
    ]),
    "bottom-hover": _withCtx(() => [
      _createVNode(_component_BookEntryActions, {
        book: _ctx.book,
        class: _normalizeClass(_ctx.coverImageClass),
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, null, 8, ["book", "class"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("a", {
        href: _ctx.bookEditorLink,
        target: "_blank",
        class: "book-link",
        "aria-label": `Continue writing ${_ctx.book.title}`
      }, [
        _createElementVNode("rbe-book", {
          class: _normalizeClass(_ctx.coverImageClass)
        }, [
          _createVNode(_component_BookCover, { book: _ctx.book }, null, 8, ["book"])
        ], 2),
        _createTextVNode(),
        _createVNode(_component_BookTitle, {
          title: _ctx.book.title,
          author: _ctx.author,
          class: "hover-book-title"
        }, null, 8, ["title", "author"])
      ], 8, _hoisted_1),
      _createTextVNode(),
      _createVNode(_component_ShareIndicator, {
        class: "share-indicator",
        "book-id": _ctx.book.id
      }, null, 8, ["book-id"]),
      _createTextVNode(),
      _createTextVNode()
    ]),
    _: 1
  }, 8, ["class"]))
}