<template>
  <button
    class="button-text neutral-button button-round button-xs"
  >
    <VuiIconEllipsis class="icon-sm" />
  </button>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class ContextMenuButton extends ClientSharedVue {}
</script>
