
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';

@Component({
  components: {
    Panel,
  },
})
export default class LeaveBookPanel extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  public get bookOwnerName(): string {
    return this.$currentBook.owner.userInfo.name;
  }

  public async openConfirmationModal(): Promise<void> {
    await this.$modals.OPEN({
      type: 'ConfirmLeaveBook',
      context: {
        bookId: this.$currentBook.id,
        bookTitle: this.$currentBook.data.title,
      },
    });
  }
}
