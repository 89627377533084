import IApi from '@reedsy/studio.shared/services/api/i-api';
import {config} from '@reedsy/studio.shared/config';
import {SECOND, SECONDS} from '@reedsy/utils.date';
import {injectable, named} from 'inversify';
import {ObjectId} from '@reedsy/utils.object-id';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {SharedModeModule} from '@reedsy/studio.shared/store/modules/mode';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import IShareDbConnection from '@reedsy/studio.shared/services/sharedb/i-sharedb-connection';
import {$inject} from '@reedsy/studio.shared/types';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';

export const CHECK_INTERVAL = 1 * SECOND;
export const OFFLINE_MODAL_DELAY = 10 * SECONDS;

const logger = loggerFactory.create('OfflineModalPlugin');

@injectable()
export abstract class OfflineModalBasePluginFactory<
  TShareDbConnection extends IShareDbConnection<any>,
> implements IStorePluginFactory {
  public abstract _shareDbConnection: TShareDbConnection;

  @$inject('Api')
  public readonly _api: IApi;

  @$inject('StoreModule')
  @named(SharedStoreName.Modals)
  public readonly _modals: SharedModalsModule;

  @$inject('StoreModule')
  @named(SharedStoreName.Mode)
  public readonly _mode: SharedModeModule;

  public plugin(): void {
    let offlineModalId: string;
    let versionModalId: string;
    let offlineSince: number = null;
    let visibleSince: number = null;
    let wantsVersionCheck = false;
    let versionCheck: Promise<string> = null;

    const {_api, _shareDbConnection, _modals, _mode} = this;

    setInterval(checkModalDisplay, 1 * SECOND);

    function checkModalDisplay(): void {
      updateOfflineSince();
      updateVisibleSince();
      if (!_shareDbConnection.socketConnected) checkVersion();
      if (!shouldShowModal()) return closeOfflineModal();
      showOfflineModal();
    }

    function updateOfflineSince(): void {
      if (_shareDbConnection.socketConnected) {
        wantsVersionCheck = true;
        offlineSince = null;
        return;
      }
      offlineSince ||= Date.now();
    }

    function updateVisibleSince(): void {
      if (document.visibilityState === 'hidden') return visibleSince = null;
      visibleSince ||= Date.now();
    }

    function shouldShowModal(): boolean {
      if (!_shareDbConnection.wantsConnection) return false;
      return [offlineSince, visibleSince]
        .every((since) => typeof since === 'number' && Date.now() - since > OFFLINE_MODAL_DELAY);
    }

    function showOfflineModal(): void {
      if (offlineModalId) return;
      _mode.OFFLINE(true);
      if (_shareDbConnection.incompatibleApi) return;
      offlineModalId = new ObjectId().toHexString();
      _modals.OPEN({id: offlineModalId, type: 'Offline'});
    }

    function closeOfflineModal(): void {
      if (!offlineModalId) return;
      _mode.OFFLINE(false);
      _modals.CLOSE(offlineModalId);
      offlineModalId = null;
    }

    async function checkVersion(): Promise<void> {
      if (versionModalId || versionCheck || !wantsVersionCheck) return;

      let version: string;
      try {
        versionCheck = _api.getAppVersion();
        version = await versionCheck;
      } catch (error) {
        // errors here aren't critical, so just swallow
        logger.debug('Bad version check response', {error});
        return;
      } finally {
        versionCheck = null;
      }

      wantsVersionCheck = false;

      if (incompatibleMajorVersions(version, config.app.version)) {
        _shareDbConnection.setIncompatibleApi();
        showVersionModal();
      }
    }

    function showVersionModal(): void {
      versionModalId = new ObjectId().toHexString();
      _modals.OPEN({id: versionModalId, type: 'UpdateAvailable'});
    }

    function incompatibleMajorVersions(v1: string, v2: string): boolean {
      const major1 = v1.split('.')[0];
      const major2 = v2.split('.')[0];
      return major1 !== major2;
    }
  }
}
