<template>
  <fieldset class="users-selector">
    <VForm
      ref="form"
      class="users-selector"
      @submit.prevent="addEmail"
    >
      <label class="add-someone-input-group">
        Add someone
        <!--
          TODO: Investigate how to improve error handling for touched inputs
          https://github.com/reedsy/reedsy-editor/issues/8526
        -->
        <VInput
          is="email-input"
          ref="input"
          v-model="inputText"
          class="input-full"
          placeholder="Enter an email address..."
          :custom-validators="customValidators"
        >
          <template #right>
            <button
              class="button-text button-accent button-sm add-invitee-button"
            >
              Add
            </button>
          </template>
        </VInput>
      </label>
    </VForm>

    <rbe-invitees-list
      class="flex-top"
    >
      <Invitee
        v-for="email in emails"
        :key="email"
        :email="email"
        :error-code="emailErrorCode(email)"
        @remove="removeEmail"
      />
    </rbe-invitees-list>

    <!--
      We need this input so that the form can validate against it
     -->
    <input
      :ref="inputFieldRef"
      type="hidden"
    >
    <ErrorMessageBox
      v-if="validationError.hasError"
    >
      {{ validationError.message }}
    </ErrorMessageBox>
  </fieldset>
</template>

<script lang="ts">
import {Component, mixins, Model, Prop, Ref} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import VInput from '@reedsy/studio.shared/components/forms/input.vue';
import {PropType} from 'vue';
import Invitee from './invitee.vue';
import ValidationMixin, {Validator} from '@reedsy/studio.shared/components/forms/mixins/validation-mixin';
import ErrorMessageBox from '@reedsy/studio.shared/components/error-message-box/error-message-box.vue';
import BaseInput from '@reedsy/studio.shared/components/forms/base-input.vue';
import VForm from '@reedsy/studio.shared/components/forms/form.vue';

@Component({
  components: {
    VForm,
    VInput,
    ErrorMessageBox,
    Invitee,
  },
})
export default class UsersSelector extends mixins(ClientSharedVue, ValidationMixin) {
  @Model({type: Array, required: true})
  public emails: string[];

  @Prop({type: Object as PropType<Record<string, string>>, default: {}})
  public failedEmails: Record<string, string>;

  @Ref('input')
  public input: VInput;

  @Ref('form')
  public form: VForm;

  public inputText = '';
  public override fieldExtraValidators: Validator[] = [
    this.validateSelector.bind(this),
  ];

  public get emailErrorCode() {
    return (email: string): string => {
      return this.failedEmails[email];
    };
  }

  private get inputElement(): HTMLInputElement {
    return this.input.$el.querySelector('input');
  }

  public addEmail(): void {
    const newEmail = this.normalizeEmail(this.inputText);
    try {
      if (!this.form.validate() || !newEmail) return;
    } finally {
      this.inputElement.focus();
    }

    this.resetValidationState();

    this.inputText = '';

    if (this.emails.includes(newEmail)) return;
    this.emails.unshift(newEmail);
  }

  public removeEmail(emailToDelete: string): void {
    const emails = new Set(this.emails);
    emails.delete(emailToDelete);
    this.emails = Array.from(emails);
  }

  private validateSelector(): string {
    return this.emails.length > 0 ? null : 'At least one email is required.';
  }

  private normalizeEmail(dirtyEmail: string): string {
    return dirtyEmail.trim().toLocaleLowerCase();
  }

  private resetValidationState(): void {
    this.reset();
    (this.input.$refs[this.inputFieldRef] as BaseInput).reset();
  }
}
</script>

<style lang="scss" scoped>
.users-selector {
  &:disabled {
    color: var(--reedsy-form-textColor-disabled);
  }
}

rbe-invitees-list {
  margin-top: $space-base;
  gap: $space-sm;
}
</style>
