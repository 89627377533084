import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import {IUserInfo} from '@reedsy/schemas.editor-collections';
import {displayedCurrentUser, IDisplayedCurrentUser} from './displayed-current-user';
import {omit} from '@reedsy/utils.object';

export interface IDisplayedCollaborator extends Omit<IDisplayedCurrentUser, 'email'> {
  role: ResourcesRole;
}

export function displayedCollaborator(user: IUserInfo, role: ResourcesRole): IDisplayedCollaborator {
  return {
    ...omit(
      displayedCurrentUser(user),
      'email',
    ),
    role,
  };
}
