import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import {IBookWordCountGoal} from '@reedsy/reedsy-sharedb/lib/common/word-count-goal/book-word-count-goal';
import {clone} from '@reedsy/utils.clone';
import {IBookshelfBookWordCountGoal} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import {Doc} from 'sharedb/lib/client';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {memoize} from '@reedsy/utils.object';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

@injectable()
export class BookWordCountGoalsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.ShareDb)
    shareDb: ShareDBModule,
  ) {
    @Module({name: StoreName.BookWordCountGoals, store})
    class BookWordCountGoals extends VuexModule {
      private booksWordCountGoalsData: {[bookId: string]: IBookWordCountGoal} = {};

      public get bookWordCountGoal() {
        return (bookId: string): IBookshelfBookWordCountGoal => {
          const bookWordCountGoalData = this.booksWordCountGoalsData[bookId];
          if (!bookWordCountGoalData) return {targetCount: 0, deadline: null};

          return {
            targetCount: bookWordCountGoalData.targetCount,
            deadline: bookWordCountGoalData.deadline,
          };
        };
      }

      @Mutation
      public DATA({id, data}: IDataMutation<'bookWordCountGoals'>): void {
        this.booksWordCountGoalsData[id] = clone(data);
      }

      @Mutation
      public APPLY_OPS({id, ops}: IApplyOpsMutation<'bookWordCountGoals'>): void {
        ops.forEach((op) => applyOp(this.booksWordCountGoalsData[id], op));
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        const query = await shareDb.subscribeQuery({
          collection: 'bookWordCountGoals',
        });

        const subscribeDocs = (docs: Doc<IBookWordCountGoal>[]): void => {
          docs.forEach((doc) => subscribeVuexToDoc(doc, this));
        };

        subscribeDocs(query.results);
        query.on('insert', subscribeDocs);

        query.on('remove', (removedDocs) => {
          removedDocs.forEach((doc) => {
            this.REMOVE_BOOK_WORD_GOAL(doc.id);
            doc.destroy();
          });
        });
      }

      @Mutation
      private REMOVE_BOOK_WORD_GOAL(bookId: string): void {
        delete this.booksWordCountGoalsData[bookId];
      }
    }
    this.Module = BookWordCountGoals;
  }
}

export type BookWordCountGoalsModule = InstanceType<BookWordCountGoalsModuleFactory['Module']>;
