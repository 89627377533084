<template>
  <rbe-screen-edge-arrow>
    <IconDrop class="arrow-background" />
    <VuiIconArrowLeft class="arrow-icon icon-md" />
  </rbe-screen-edge-arrow>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import IconDrop from '@reedsy/studio.shared/components/icons/icon-drop.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    IconDrop,
  },
})
export default class ScreenEdgeArrow extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
rbe-screen-edge-arrow {
  cursor: pointer;

  &:hover .arrow-background :deep(#icon-fill) {
    fill: var(--NEUTRAL-COLOR);
  }

  .arrow-background {
    height: 100%;
    width: auto;

    :deep(#icon-fill) {
      fill: var(--NEUTRAL-COLOR-weak);
    }

    svg {
      filter: drop-shadow(0 0.125rem 0.25rem var(--BOX-SHADOW-base-color))
      drop-shadow(0 0.125rem 1rem var(--BOX-SHADOW-light-color));
    }
  }

  .arrow-icon {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 15%;
    height: 35%;
    width: auto;
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(90deg);
  }

  &.down {
    transform: rotate(180deg);
  }

  &.left {
    transform: rotate(270deg);
  }
}
</style>
