<template>
  <rbe-export-trim-sizes>
    <rbe-export-sizes-graphics v-if="trimSizes">
      <div
        v-for="size in trimSizes"
        :key="size.sizeId"
        :class="{
          'active': (size.sizeId === trimSize),
        }"
        :style="{
          height: `${ size.percentageHeight }%`,
          width: `${ size.percentageWidth }%`,
        }"
        class="export-size-option"
        data-test="trim-size"
        :data-test-size-name="size.name"
        @click="trimSize = size.sizeId"
      />
    </rbe-export-sizes-graphics>

    <rbe-export-sizes-legend>
      <strong>
        {{ activeSizeDetails.graphicalSelectorName }}
      </strong>

      <ul>
        <li>
          Width: {{ activeSizeDetails.width }}”
        </li>
        <li>
          Height: {{ activeSizeDetails.height }}”
        </li>
      </ul>
    </rbe-export-sizes-legend>
  </rbe-export-trim-sizes>
</template>

<script lang="ts">
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {TrimSize} from '@reedsy/schemas.workers.book-export';
import {TRIM_SIZE_DETAILS_OBJECT} from './trim-sizes-details';
import {clone} from '@reedsy/utils.clone';
import {ITrimSizeDetails} from './i-trim-size-details';

@Component({})
export default class ExportTrimSizes extends BookshelfVue {
  @Model({type: String})
  public trimSize: TrimSize;

  public trimSizes = clone(TRIM_SIZE_DETAILS_OBJECT);

  public get activeSizeDetails(): ITrimSizeDetails {
    return this.trimSizes[this.trimSize];
  }
}
</script>

<style lang="scss" scoped>
rbe-export-trim-sizes {
  $export-sizes-color: $blue;
  $export-sizes-v-ratio: 150;

  margin: 0 auto;
  padding-top: ($export-sizes-v-ratio * 1%);
  position: relative;
  width: 100%;

  rbe-export-sizes-graphics {
    .export-size-option {
      border: $border-width dashed color($export-sizes-color, light);
      border-radius: $border-radius-base;
      bottom: 0;
      cursor: pointer;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;

      @include transition(background);

      $graphic-border-width: $border-width-lg;

      &:hover {
        border: $graphic-border-width solid color($export-sizes-color, light);
      }

      &.active {
        background-color: color($export-sizes-color, fade);
        border: $graphic-border-width solid color($export-sizes-color, light);
        cursor: default;
      }
    }
  }

  rbe-export-sizes-legend {
    bottom: 1rem;
    color: color($export-sizes-color, medium);
    left: 1rem;
    pointer-events: none;
    position: absolute;

    ul, li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
