import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {IBookshelfBook} from './i-bookshelf-book';
import {BooksTotalWordCountsModule} from '@reedsy/studio.home.bookshelf/store/modules/books-total-word-counts';
import {BookWordCountGoalsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-word-count-goals';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {UserBookMetadataModule} from '@reedsy/studio.home.bookshelf/store/modules/user-book-metadata';
import {BookFilterType} from './book-filter-type';
import {BookColorPool} from '@reedsy/studio.home.bookshelf/utils/book-color-pool/book-color-pool';
import {IBookColorPalette} from '@reedsy/studio.home.bookshelf/utils/book-color-pool/i-book-color-pool';
import {BooksImportsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-imports';
import {ImportState} from '@reedsy/reedsy-sharedb/lib/common/book-import/import-state';
import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {ReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {searchBook, searchBookImport} from './fuse-search';
import {BookshelfEntryType, IBookshelfEntry} from './i-bookshelf-entry';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';

const INITIAL_MAX_SHOWN_ENTRIES = 14;
const NEXT_PAGE_SIZE = 10;

@injectable()
export class BookshelfModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,

    @$inject('Router')
    router: ReedsyRouter,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    user: SharedUserModule,

    @$inject('StoreModule')
    @named(StoreName.BooksTotalWordCounts)
    booksTotalWordCounts: BooksTotalWordCountsModule,

    @$inject('StoreModule')
    @named(StoreName.BookWordCountGoals)
    booksWordCountGoals: BookWordCountGoalsModule,

    @$inject('StoreModule')
    @named(StoreName.BookDetails)
    BookDetails: BookDetailsModule,

    @$inject('StoreModule')
    @named(StoreName.UserBookMetadata)
    UserBookMetadata: UserBookMetadataModule,

    @$inject('StoreModule')
    @named(StoreName.BooksImports)
    BookImports: BooksImportsModule,

    @$inject('BookColorPool')
    bookColorPool: BookColorPool,
  ) {
    @Module({name: StoreName.Bookshelf, store})
    class Bookshelf extends VuexModule {
      public bookFilterType = BookFilterType.AllBooks;
      public searchValue = '';
      public maxShownEntries = INITIAL_MAX_SHOWN_ENTRIES;
      public showOnlyArchived = false;
      private activeEntryId: string = null;
      private deletedBooks: {[bookId: string]: boolean} = {};

      public get book() {
        return (bookId: string): IBookshelfBook => {
          const bookDetails = BookDetails.details(bookId);
          if (!bookDetails) return null;

          const bookImportData = BookImports.importDataByBookId[bookId];
          if (
            bookImportData &&
            bookImportData.state !== ImportState.Processed
          ) return null;

          return {
            ...UserBookMetadata.userBookMetadata(bookId),
            ...BookDetails.details(bookId),
            role: BookDetails.userBookRole({userUuid: user.uuid, bookId}),
            totalWordCount: booksTotalWordCounts.bookTotalWordCount(bookId),
            wordCountGoal: booksWordCountGoals.bookWordCountGoal(bookId),
          };
        };
      }

      public get bookColor() {
        return (bookId: string): IBookColorPalette => {
          return bookColorPool.get(bookId);
        };
      }

      public get books(): IBookshelfBook[] {
        return UserBookMetadata.booksOrder
          .map((bookId) => this.book(bookId))
          .filter(Boolean);
      }

      public get booksIds(): string[] {
        return this.books.map((book) => book.id);
      }

      public get hasBooks(): boolean {
        return !!this.books.length;
      }

      public get activeEntry(): IBookshelfEntry {
        return this.displayedBookshelfEntries[this.activeEntryIndex] || null;
      }

      public get activeEntryIndex(): number {
        if (!this.activeEntryId) return 0;
        const index = this.displayedBookshelfEntries.findIndex((entry) => entry.id === this.activeEntryId);
        return Math.max(index, 0);
      }

      public get filteredBookImports(): IBookImport[] {
        return searchBookImport(this.displayableImports, this.normalizedSearchValue);
      }

      public get filteredBooks(): IBookshelfBook[] {
        let filteredBooks = this.displayableBooks
          .filter((book) => !book.hiddenAt);

        if (this.bookFilterType === BookFilterType.Shared) {
          filteredBooks = filteredBooks.filter((book) => book.ownerId !== user.id);
        }
        return searchBook(filteredBooks, this.normalizedSearchValue);
      }

      public get displayedBookshelfEntries(): IBookshelfEntry[] {
        return this.bookshelfEntries.slice(0, this.maxShownEntries);
      }

      public get hasMoreItemsToShow(): boolean {
        return this.bookshelfEntries.length > this.maxShownEntries;
      }

      public get filteredArchivedBooks(): IBookshelfBook[] {
        const archivedBooks = this.displayableBooks
          .filter((book) => this.isArchived(book));

        return searchBook(archivedBooks, this.normalizedSearchValue);
      }

      public get isArchived() {
        return (book: IBookshelfBook): boolean => {
          return !!book?.hiddenAt;
        };
      }

      public get hasGoalSet() {
        return (book: IBookshelfBook): boolean => {
          return !!book?.wordCountGoal?.targetCount;
        };
      }

      public get hasSomeFilteredBooks(): boolean {
        return !!(this.filteredBooks.length || this.filteredBookImports.length);
      }

      public get isFiltered(): boolean {
        return !!this.normalizedSearchValue || this.bookFilterType !== BookFilterType.AllBooks;
      }

      public get bookshelfEntries(): IBookshelfEntry[] {
        if (this.showOnlyArchived) {
          return this.filteredArchivedBooks.map(bookToBookshelfEntry);
        } else {
          return [
            ...this.filteredBookImports.map(bookImportToBookshelfEntry),
            ...this.filteredBooks.map(bookToBookshelfEntry),
          ];
        }
      }

      private get displayableBooks(): IBookshelfBook[] {
        return this.books
          .filter((book) => !this.deletedBooks[book.id])
          .filter((book) => !this.isBookBeingImported(book.id));
      }

      private get normalizedSearchValue(): string {
        return this.searchValue.trim();
      }

      private get displayableImports(): IBookImport[] {
        return BookImports
          .allImports
          .filter((bookImport) => {
            if (!bookImport.importData.bookId) return true;
            return this.isBookBeingImported(bookImport.importData.bookId);
          });
      }

      private get isBookBeingImported() {
        return (bookId: string): boolean => {
          const bookImport = BookImports.importDataByBookId[bookId];
          if (!bookImport) return false;
          return !BookDetails.details(bookId);
        };
      }

      @Action
      @NotifyError('Cannot delete book')
      public async deleteBook(bookId: string): Promise<void> {
        await api.deleteBook(bookId);
        this.DELETED_BOOKS(bookId);
        await router.push({
          name: BookshelfRouteName.Bookshelf,
        });
      }

      @Action
      public showAllBookshelfEntries(): void {
        this.MAX_SHOWN_BOOKSHELF_ENTRIES(Number.POSITIVE_INFINITY);
      }

      @Action
      public showMoreBookshelfEntries(): void {
        if (!this.hasMoreItemsToShow) return;
        this.MAX_SHOWN_BOOKSHELF_ENTRIES(this.maxShownEntries + NEXT_PAGE_SIZE);
      }

      @Action
      public search(searchValue: string): void {
        this._SEARCH_VALUE(searchValue);
        this.MAX_SHOWN_BOOKSHELF_ENTRIES(INITIAL_MAX_SHOWN_ENTRIES);
        this.setActiveEntry({entryId: this.displayedBookshelfEntries[0]?.id});
      }

      @Action
      public async setActiveEntry({entryId, clearSearch}: {entryId: string; clearSearch?: boolean}): Promise<void> {
        if (clearSearch) this._SEARCH_VALUE('');
        this.ACTIVE_ENTRY_ID(entryId);
      }

      @Mutation
      public BOOKS_FILTER_TYPE(bookFilterType: BookFilterType): void {
        this.bookFilterType = bookFilterType || BookFilterType.AllBooks;
      }

      @Mutation
      public DELETED_BOOKS(bookId: string): void {
        this.deletedBooks[bookId] = true;
      }

      @Mutation
      public SHOW_ONLY_ARCHIVED(value: boolean): void {
        this.showOnlyArchived = value;
      }

      @Mutation
      public _SEARCH_VALUE(search: string): void {
        this.searchValue = search || '';
      }

      @Mutation
      private ACTIVE_ENTRY_ID(entryId: string): void {
        if (entryId === this.activeEntryId) return;
        this.activeEntryId = entryId || null;
      }

      @Mutation
      private MAX_SHOWN_BOOKSHELF_ENTRIES(value: number): void {
        this.maxShownEntries = value;
      }
    }
    this.Module = Bookshelf;
  }
}

export type BookshelfModule = InstanceType<BookshelfModuleFactory['Module']>;

function bookToBookshelfEntry(book: IBookshelfBook): IBookshelfEntry {
  return {
    item: book,
    id: book.id,
    type: BookshelfEntryType.Book,
  };
}

function bookImportToBookshelfEntry(bookImport: IBookImport): IBookshelfEntry {
  return {
    item: bookImport,
    id: bookImport._id,
    type: BookshelfEntryType.BookImport,
  };
}
