import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiFileUpload = _resolveComponent("VuiFileUpload")!
  const _component_BaseFileInput = _resolveComponent("BaseFileInput")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["file-upload", {invalid: _ctx.error.hasError}])
  }, [
    _createVNode(_component_VuiFileUpload, _mergeProps({ ref: "vuiFileUpload" }, _ctx.$attrs, {
      class: "vui-file-upload",
      onError: _ctx.handleVuiFileError,
      onFileUpdated: _ctx.fileUpdated
    }), null, 16, ["onError", "onFileUpdated"]),
    _createTextVNode(),
    _createVNode(_component_BaseFileInput, {
      "model-value": _ctx.file,
      required: _ctx.required || null,
      onValidationError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.error = $event))
    }, null, 8, ["model-value", "required"]),
    _createTextVNode(),
    (_ctx.error.hasError)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          error: _ctx.error.message
        }, null, 8, ["error"]))
      : _createCommentVNode("", true)
  ], 2))
}