<template>
  <rbe-premium-badge-info>
    <VuiIconInfo class="info-icon icon-sm" />
    <PremiumIcon />
  </rbe-premium-badge-info>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import PremiumIcon from './premium-icon.vue';

@Component({
  components: {
    PremiumIcon,
  },
})
export default class PremiumBadgeInfo extends ClientSharedVue {
}
</script>

<style lang="scss" scoped>
rbe-premium-badge-info {
  cursor: pointer;
  color: var(--TEXT-INVERTED-COLOR);
  background: var(--PREMIUM-COLOR-strong);
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  gap: $space-xxs;
  padding-left: $space-xs;

  @include font-family($controls, bold);
  @include transition(background-color);

  &:hover {
    background-color: var(--PREMIUM-COLOR-strongest);

    :deep(.icon-circle) {
      background-color: var(--PREMIUM-COLOR-strong);
    }
  }

  :deep(.premium-icon) .vui-icon.icon-circle {
    margin: 0;
  }

  .info-icon {
    color: var(--TEXT-INVERTED-COLOR);
    height: 0.625rem;
  }
}
</style>
