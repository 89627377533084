<template>
  <rbe-book-loader>
    <SkeletonLoader class="book-cover" />
    <SkeletonLoader class="book-title" />
  </rbe-book-loader>
</template>

<script lang="ts">
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class BookLoader extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
@import './mixins';

rbe-book-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;

  .book-cover {
    border-radius: $border-radius-lg;
    height: $book-slide-height;

    @include books-slider-entry-size;
  }

  .book-title {
    margin-top: $space-md;
    width: 40%;
  }
}
</style>
