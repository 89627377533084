import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cc7dce02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal reedsy-accented" }
const _hoisted_2 = {
  key: 0,
  class: "modal-head"
}
const _hoisted_3 = { class: "ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconCross = _resolveComponent("VuiIconCross")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.headless)
      ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ], true)
          ]),
          _createTextVNode(),
          (_ctx.cancelable)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "button-icon button-sm close-modal-button reedsy-accented",
                type: "button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }, [
                _createVNode(_component_VuiIconCross, { class: "base-icon" })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(),
    _createElementVNode("rbe-modal-content", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createTextVNode(),
    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
  ]))
}