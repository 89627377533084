import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NotFoundPartial = _resolveComponent("NotFoundPartial")!

  return (_openBlock(), _createBlock(_component_NotFoundPartial, null, {
    "home-link": _withCtx(() => [
      _createVNode(_component_router_link, { to: _ctx.bookshelfRoute }, {
        default: _withCtx(() => [
          _createTextVNode("\n        Return to Reedsy\n      ")
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _: 1
  }))
}