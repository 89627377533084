import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bc512dd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScalingPremiumBadge = _resolveComponent("ScalingPremiumBadge")!

  return (_ctx.shouldShow)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "premium-ad-banner",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$subscriptionModal.open && _ctx.$subscriptionModal.open(...args)))
      }, [
        (!_ctx.$subscription.hasAnyPaidFeature)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "\n      Unlock more with\n    "))
          : (_ctx.$subscription.isTrial)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.trialMessage), 1))
            : _createCommentVNode("", true),
        _createTextVNode(),
        _createVNode(_component_ScalingPremiumBadge)
      ]))
    : _createCommentVNode("", true)
}