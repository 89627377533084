<template>
  <component
    :is="wrapperComponent"
    v-tooltip="isFailed && tooltipOptions"
    :href="downloadExportUrl"
    class="exports-list-entry"
    :class="{
      loading: isPending,
      error: isFailed
    }"
  >
    <rbe-row>
      <ExportTypeIndicator :book-export="bookExport" />
      <rbe-right-column>
        <rbe-titles-wrapper>
          <h1>
            {{ title }}
          </h1>
          <p>
            <span class="export-type">{{ exportTypeDisplayValue }}</span>
            •
            <span class="export-creation-date">{{ createdAt }}</span>
          </p>
        </rbe-titles-wrapper>
        <ExportAction
          :book-export="bookExport"
          class="hidden-until-md"
        />
      </rbe-right-column>
    </rbe-row>
    <rbe-row class="visible-until-sm">
      <ExportAction
        :book-export="bookExport"
      />
    </rbe-row>
  </component>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportTypeIndicator from './export-type-indicator.vue';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import {IBookExportData} from '@reedsy/reedsy-sharedb/lib/common/book/book-export-data';
import ExportAction from './export-action/export-action.vue';
import longDate from '@reedsy/studio.shared/filters/date-with-year';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import {ExportState} from '@reedsy/reedsy-sharedb/lib/common/book-export/export-state';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookDataStoreCoordinator} from '@reedsy/studio.home.bookshelf/store/helpers/book-data-store-coordinator';

const EXPORT_TYPE_DISPLAY_VALUES: Record<ExportType, string> = {
  [ExportType.Docx]: 'DOCX',
  [ExportType.Pdf]: 'PDF',
  [ExportType.EPub]: 'EPUB',
};

@Component({
  components: {
    ExportTypeIndicator,
    ExportAction,
  },
})
export default class ExportsListEntry extends BookshelfVue {
  @$lazyInject('BookshelfBookDataStoreCoordinator')
  public $bookDataStoreCoordinator: BookDataStoreCoordinator;

  @Prop({type: Object, required: true})
  public bookExport: IBookExport;

  public get exportData(): IBookExportData {
    return this.bookExport.exportData;
  }

  public get exportType(): ExportType {
    return this.exportData.type;
  }

  public get exportState(): ExportState {
    return this.exportData.state;
  }

  public get title(): string {
    return this.exportData.options.title;
  }

  public get isFailed(): boolean {
    return this.exportState === ExportState.Failed;
  }

  public get isPending(): boolean {
    return this.exportData.state === ExportState.Pending;
  }

  public get exportTypeDisplayValue(): string {
    return EXPORT_TYPE_DISPLAY_VALUES[this.exportType];
  }

  public get createdAt(): string {
    return longDate(this.bookExport.exportData.createdAt);
  }

  public get hasDownloadLink(): boolean {
    return this.exportState == ExportState.Processed;
  }

  public get downloadExportUrl(): string {
    return this.hasDownloadLink ?
      this.$bookDataStoreCoordinator
        .getModule('bookExports')
        .downloadExportLink(this.bookExport._id) : '';
  }

  public get wrapperComponent(): string {
    return this.hasDownloadLink ? 'a' : 'div';
  }

  public get tooltipOptions(): any {
    return {
      content: `
          Try to export book again, if problem persists,
          please contact our support team using help button.
      `,
      placement: 'bottom-start',
      popperClass: 'failed-export-popper',
      arrowPadding: 12,
    };
  }
}
</script>

<style lang="scss">
.failed-export-popper {
  max-width: 20rem;
  top: $space-md;

  /* stylelint-disable-next-line selector-class-pattern */
  .v-popper__arrow-container {
    left: 1.6rem!important;
  }
}
</style>

<style lang="scss" scoped>
$max-export-action-width: 8rem;

.exports-list-entry {
  width: 100%;

  &.error,
  &.loading {
    rbe-row rbe-right-column rbe-titles-wrapper {
      opacity: 0.5;
    }
  }

  rbe-row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;

    &:nth-child(2) {
      margin-top: $space-md;

      rbe-export-action,
      :deep(button),
      :deep(.button) {
        width: 100%;
      }
    }

    rbe-right-column {
      display: flex;
      justify-content: space-between;
      padding-left: $space-md;
      height: 100%;
      width: calc(100% - $export-type-indicator-width);
      box-sizing: border-box;
      align-items: center;

      rbe-titles-wrapper {
        max-width: 100%;

        @include screen-more-than(sm) {
          max-width: calc(100% - $max-export-action-width - 2 * $space-md);
        }

        h1 {
          @include line-clamp(2);
          @include font-family($controls, bold);

          font-size: $font-size-base;
        }

        p {
          margin-top: $space-sm;
        }

        .export-creation-date {
          color: var(--NEUTRAL-COLOR-strongest)
        }

        .export-type {
          @include font-family($controls, bold);
        }
      }

      .export-action {
        max-width: $max-export-action-width;
      }
    }
  }
}
</style>
