<template>
  <Panel class="bordered auth-account-settings-banner">
    <rbe-account-info>
      <rbe-avatar-wrapper>
        <Avatar
          :user-info="user"
          bordered
          class="xl"
        />
      </rbe-avatar-wrapper>

      <rbe-info>
        <h1>
          Logged in as <span
            ref="userName"
            class="user-name"
          >{{ userName }}</span>
        </h1>
        <p>
          Your name, email, and password can be configured on your Reedsy account.
        </p>
      </rbe-info>
      <rbe-button-wrapper>
        <a
          :href="_navigation.authAccountSettingsUrl"
          class="button button-text button-accent"
          title="Make changes"
        >
          Make&nbsp;changes
        </a>
      </rbe-button-wrapper>
    </rbe-account-info>

    <template #footer>
      <rbe-footer>
        <p>
          Your Reedsy account can be used to
          <a
            :href="_navigation.authDashboardUrl"
            class="text-link"
            data-test-id="account-dashboard-link"
            target="_blank"
            title="Sign in to other Reedsy Apps"
          >sign in to other Reedsy Apps</a>.
        </p>

        <a
          :href="_navigation.authDashboardUrl"
          data-test-id="account-dashboard-img-link"
          target="_blank"
          title="Reedsy account"
        >
          <img
            src="@/assets/inlined/reedsy-account.svg"
          >
        </a>
      </rbe-footer>
    </template>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({
  components: {
    Panel,
    Avatar,
  },
})
export default class AuthAccountSettingsBanner extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInject('Navigation')
  public readonly _navigation: INavigation;

  public get user(): IUserInfo {
    return this.$user.info;
  };

  public get userName(): string {
    return this.user.name;
  }
}
</script>

<style lang="scss" scoped>
$panel-padding: 0.5rem;
$panel-border-radius: 0.5rem;
$account-logo-size: 1.25rem;

.auth-account-settings-banner {
  padding: 0;
  border-color: var(--accent-color);
}

rbe-account-info {
  padding: $space-md;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $space-md;

  rbe-info {
    flex-grow: 0;
    flex-shrink: 1;

    h1 {
      font-size: $font-size-lg;
      margin-bottom: $space-sm;

      .user-name {
        text-transform: capitalize;
      }
    }
  }

  @include screen-less-than(md) {
    flex-direction: column;
    gap: $space-lg;

    rbe-info {
      text-align: center;
    }

    rbe-button-wrapper, rbe-button-wrapper .button {
      width: 100%;
    }
  }
}

rbe-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--TEXT-BACKGROUND-ALT);
  padding: $space-md ($space-md + $panel-padding);
  border-bottom-left-radius: $panel-border-radius;
  border-bottom-right-radius: $panel-border-radius;

  @include screen-less-than(md) {
    flex-direction: column;
    gap: $space-lg;
  }

  p {
    font-size: $font-size-sm;
  }

  a {
    height: $account-logo-size;

    img {
      height: 100%;
      width: auto;
    }
  }
}
</style>
