
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import {FormattingOption} from './formatting-option';
import {ExportType, NotesPosition} from '@reedsy/schemas.workers.book-export';
import {ITabOption} from '@reedsy/studio.home.bookshelf/components/tab-options/i-tab-option';
import TabOptions from '@reedsy/studio.home.bookshelf/components/tab-options/tab-options.vue';
import {entries} from '@reedsy/utils.iterable';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import PanelBodyTitle from '@reedsy/studio.home.bookshelf/components/panel/panel-body-title.vue';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';

@Component({
  components: {
    Panel,
    TabOptions,
    PanelBodyTitle,
  },
})
export default class BookFormattingSelector extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public readonly NotesPosition = NotesPosition;

  public readonly formattingOptions = [
    {
      description: '',
      image: require('@/assets/inlined/formatting/chapter-number.svg'),
      title: 'Hide chapter numbers',
      value: FormattingOption.HideChapterNumbers,
    },
    {
      description: 'at start of chapter',
      image: require('@/assets/inlined/formatting/dropcap.svg'),
      title: 'Drop caps',
      value: FormattingOption.DropCaps,
    },
  ] as const satisfies readonly ITabOption<FormattingOption>[];

  public get isPdfExport(): boolean {
    return this.$bookExportSubmission.exportType === ExportType.Pdf;
  }

  public get endnotesPosition(): NotesPosition {
    return this.$bookExportSubmission.endnotePosition;
  }

  public set endnotesPosition(position: NotesPosition) {
    this.$bookExportSubmission.ENDNOTE_POSITION(position);
  }

  public get selectedFormattingObject(): Record<FormattingOption, boolean> {
    return {
      [FormattingOption.DropCaps]: this.$bookExportSubmission.dropCaps,
      [FormattingOption.HideChapterNumbers]: !this.$bookExportSubmission.chapterNumber,
    };
  }

  public get selectedFormatting(): FormattingOption[] {
    return entries(this.selectedFormattingObject)
      .filter(([_, isFormattingOn]) => isFormattingOn)
      .map(([formattingOptionName]) => formattingOptionName as FormattingOption);
  }

  public set selectedFormatting(options: FormattingOption[]) {
    this.$bookExportSubmission.DROP_CAPS(options.includes(FormattingOption.DropCaps));
    this.$bookExportSubmission.CHAPTER_NUMBER(!options.includes(FormattingOption.HideChapterNumbers));
  }

  public get bookSettingsRoute(): MetaRawLocation {
    return {name: BookshelfRouteName.BookSettings};
  }
}
