import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_VuiDropdown = _resolveComponent("VuiDropdown")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref: "wrapper",
      class: _normalizeClass(["dropdown", {invalid: _ctx.error.hasError}])
    }, [
      _createVNode(_component_BaseInput, _mergeProps({
        ref: "input",
        "model-value": _ctx.value
      }, _ctx.$attrs, {
        class: "hidden-input",
        onValidationError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.error = $event))
      }), null, 16, ["model-value"]),
      _createTextVNode(),
      _createVNode(_component_VuiDropdown, _mergeProps({
        ref: "dropdown",
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
      }, _ctx.$props, {
        class: ["full-width", _ctx.theme],
        "sync-options-width": true
      }), null, 16, ["modelValue", "class"])
    ], 2),
    _createTextVNode(),
    _createVNode(_component_ErrorMessage, {
      error: _ctx.error.message
    }, null, 8, ["error"])
  ]))
}