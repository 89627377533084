import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c717f2e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vui-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewExportButton = _resolveComponent("NewExportButton")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("rbe-export-page-actions", null, [
    _createElementVNode("rbe-tabs-wrapper", _hoisted_1, [
      _createVNode(_component_Tabs, {
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
        tabs: _ctx.tabs,
        class: "page-selector"
      }, {
        [`tab-${_ctx.BookshelfRouteName.BookExportSubmission}`]: _withCtx(() => [
          _createVNode(_component_NewExportButton)
        ]),
        _: 2
      }, 1032, ["modelValue", "tabs"])
    ])
  ]))
}