import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookSettingsPanel = _resolveComponent("BookSettingsPanel")!
  const _component_BookCoverUploader = _resolveComponent("BookCoverUploader")!
  const _component_ImageResolutionInfoPanel = _resolveComponent("ImageResolutionInfoPanel")!
  const _component_BookNotificationsSettingsPanel = _resolveComponent("BookNotificationsSettingsPanel")!
  const _component_BookArchivePanel = _resolveComponent("BookArchivePanel")!
  const _component_LeaveBookPanel = _resolveComponent("LeaveBookPanel")!
  const _component_LayoutWithRightSideBar = _resolveComponent("LayoutWithRightSideBar")!

  return (_openBlock(), _createElementBlock("rbe-book-settings", null, [
    _createVNode(_component_LayoutWithRightSideBar, null, {
      "left-side": _withCtx(() => [
        _createVNode(_component_BookSettingsPanel),
        _createTextVNode(),
        _createVNode(_component_BookCoverUploader, { class: "visible-until-sm mobile-book-cover-uploader" }),
        _createTextVNode(),
        _createVNode(_component_ImageResolutionInfoPanel, { class: "visible-until-sm" }),
        _createTextVNode(),
        _createVNode(_component_BookNotificationsSettingsPanel),
        _createTextVNode(),
        (_ctx.isUserBookOwner)
          ? (_openBlock(), _createBlock(_component_BookArchivePanel, {
              key: 0,
              ref: "bookArchivePanel"
            }, null, 512))
          : _createCommentVNode("", true),
        _createTextVNode(),
        (!_ctx.isUserBookOwner)
          ? (_openBlock(), _createBlock(_component_LeaveBookPanel, {
              key: 1,
              ref: "leaveBookPanel"
            }, null, 512))
          : _createCommentVNode("", true)
      ]),
      "right-side": _withCtx(() => [
        _createVNode(_component_BookCoverUploader),
        _createTextVNode(),
        _createVNode(_component_ImageResolutionInfoPanel)
      ]),
      _: 1
    })
  ]))
}