import {IPriceCurrencyOption} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-currency-options-response';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';

export function choosePrice(
  currency: SupportedCurrency,
  options: ReadonlyArray<IPriceCurrencyOption>,
): IPriceCurrencyOption {
  let fallback: IPriceCurrencyOption;
  for (const option of options) {
    if (!fallback || option.currency === 'usd') fallback = option;
    if (option.currency === currency) return option;
  }
  return fallback;
}
