import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6af41876"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "button button-icon button-translucent" }
const _hoisted_4 = ["href", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconSettings = _resolveComponent("VuiIconSettings")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("rbe-selected-book-action-bar", null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.isBookImport)
          ? (_openBlock(), _createElementBlock("rbe-book-import-actions", _hoisted_1, [
              _createElementVNode("button", {
                type: "button",
                class: "button-accent button-text cancel-import-button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelImport && _ctx.cancelImport(...args)))
              }, "\n          Cancel import\n        ")
            ]))
          : (_openBlock(), _createElementBlock("rbe-book-actions", _hoisted_2, [
              _createVNode(_component_router_link, {
                ref: "manageLink",
                to: _ctx.bookLocation,
                class: "manage-link",
                disabled: _ctx.disabled
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, [
                    _createVNode(_component_VuiIconSettings)
                  ]),
                  _createTextVNode("\n          Manage\n        ")
                ]),
                _: 1
              }, 8, ["to", "disabled"]),
              _createTextVNode(),
              _createElementVNode("a", {
                href: _ctx.bookEditorLink,
                class: "button button-accent button-text write-book-link",
                disabled: _ctx.disabled,
                target: "_blank"
              }, "\n          Write\n        ", 8, _hoisted_4)
            ]))
      ]),
      _: 1
    })
  ]))
}