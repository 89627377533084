<template>
  <rbe-new-book-action-bar>
    <button
      type="button"
      class="button-transparent create-book-button ignore-hover"
      @click="openCreateBookModal"
    >
      <span class="button button-icon button-accent">
        <VuiIconPlus />
      </span>
      Create book
    </button>
    <button
      type="button"
      class="button-transparent import-book-button ignore-hover"
      @click="openImportBookModal"
    >
      Import
      <span class="button button-icon button-translucent">
        <VuiIconCloud />
      </span>
    </button>
  </rbe-new-book-action-bar>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@Component({})
export default class NewBookActionBar extends BookshelfVue {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public openCreateBookModal(): void {
    this.$modals.OPEN('CreateBook');
  }

  public openImportBookModal(): void {
    this.$modals.OPEN({
      type: 'ImportBook',
      context: {},
    });
  }
}
</script>

<style lang="scss" scoped>
rbe-new-book-action-bar {
  display: flex;
  justify-content: space-between;

  button {
    cursor: pointer;
    color: var(--text-color);

    span.button-icon {
      margin: 0 $space-md;
    }
  }
}
</style>
