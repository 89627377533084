<template>
  <rbe-app-loader>
    <VuiLoadingIndicator class="color-accent-alt" />
  </rbe-app-loader>
</template>

<style lang="scss" scoped>
rbe-app-loader {
  display: flex;
  justify-content: center;
  padding-top: var(--initial-loader-padding-top);
}
</style>
