import Notify from '@reedsy/studio.shared/services/notify/notify';

export function NotifyError(message: string) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor): void {
    const method = descriptor.value;

    descriptor.value = function(...args: any[]) {
      try {
        const result = method
          .apply(this, args);

        if (result instanceof Promise) {
          return result.catch(() => Notify.error({message}));
        } else {
          return result;
        }
      } catch {
        Notify.error({message});
      }
    };
  };
}
