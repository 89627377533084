<template>
  <button
    class="loading-button"
    :class="{loading}"
    :disabled="loading || null"
  >
    <span class="button-content">
      <slot />
    </span>
    <span class="spinner-container">
      <VuiLoadingIndicator class="sm" />
    </span>
  </button>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class LoadingButton extends ClientSharedVue {
  @Prop({type: Boolean, required: true})
  public loading: boolean;
}
</script>

<style lang="scss" scoped>
.loading-button {
  position: relative;

  .spinner-container {
    display: none;
    position: absolute;
    inset: 0;
    align-items: center;
    justify-content: center;
  }

  &.loading {
    .spinner-container {
      display: flex;
    }

    .button-content {
      visibility: hidden;
      vertical-align: middle;
    }
  }

  .vui-loading-indicator {
    margin-bottom: 0;
  }
}
</style>
