import IconDrag from './icons/drag';
import IconClose from './icons/close';
import SASS from '@reedsy/studio.shared/utils/sass';
import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';

export default class BlockControls extends JsxComponent {
  public static styleString = `
  .ql-block-controls {
    display: flex;
    flex-direction: column;
    gap: ${SASS.space.sm}px;
    width: var(--block-controls-action-size);
  }

  .ql-block-controls-remove, .ql-block-controls-drag {
    border-radius: ${SASS.borderRadius.base}px;
    width: var(--block-controls-action-size);
    height: var(--block-controls-action-size);
    display: flex;
    place-content: center;
    align-items: center;
    color: var(--NEUTRAL-COLOR-strong);
  }

  .ql-block-controls-drag {
    cursor: move;
  }

  .ql-block-controls-remove:hover {
    color: var(--NEUTRAL-COLOR-strongest);
  }

  .ql-block-controls-drag:hover {
    color: var(--NEUTRAL-COLOR-strongest);
    background-color: var(--NEUTRAL-COLOR-weak);
  }
  `;

  public override $props: {
    onClose: () => void;
  };

  public render(): HTMLElement {
    return (
      <rbe-block-controls
        class="ql-block-controls"
        contenteditable="false"
      >
        <button
          class="ql-block-controls-remove"
          onClick={this.$props.onClose}
        >
          <IconClose />
        </button>
        <div class="ql-block-controls-drag">
          <IconDrag />
        </div>
      </rbe-block-controls>
    );
  }
}
