import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/parrot.png'


const _withScopeId = (n: any) => (_pushScopeId("data-v-02a976ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "parrot-image",
  src: _imports_0,
  alt: "Reedsy Studio"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumBanner = _resolveComponent("PremiumBanner")!
  const _component_TopBarButton = _resolveComponent("TopBarButton")!
  const _component_UserSettingsMenu = _resolveComponent("UserSettingsMenu")!
  const _component_VuiTopMenu = _resolveComponent("VuiTopMenu")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.config.features.subscriptions)
      ? (_openBlock(), _createBlock(_component_PremiumBanner, { key: 0 }))
      : _createCommentVNode("", true),
    _createTextVNode(),
    _createVNode(_component_VuiTopMenu, {
      "home-title": "Reedsy",
      "home-url": _ctx.homeUrl,
      "app-name": "studio",
      class: "top-bar",
      "menu-options": _ctx.menuOptions,
      onMenuOptionClick: _ctx.handleMenuOptionClick
    }, {
      left: _withCtx(() => [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.$layout.mobileMenuCanBeShown)
              ? (_openBlock(), _createBlock(_component_TopBarButton, {
                  key: 0,
                  class: "toggle-left-sidebar-button transparent",
                  icon: "VuiIconMenu",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$layout.toggleMobileMenu()))
                }))
              : (_openBlock(), _createElementBlock("img", _hoisted_1))
          ]),
          _: 1
        })
      ]),
      right: _withCtx(() => [
        _createVNode(_component_UserSettingsMenu)
      ]),
      _: 1
    }, 8, ["home-url", "menu-options", "onMenuOptionClick"])
  ]))
}