import {VuexModule, Mutation, Action} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {Store} from 'vuex';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';
import {ScreenSize} from '@reedsy/studio.shared/store/modules/browser/screen-size';

@injectable()
export class BookshelfLayoutModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(SharedStoreName.Browser)
    Browser: SharedBrowserModule,
  ) {
    @Module({name: StoreName.Layout, store})
    class BookshelfLayout extends VuexModule {
      public isLoadingCurrentUser = true;
      public isLoadingBooksList = true;
      public isLoadingBookData = true;
      public darkContentIsAllowed = true;
      public layoutTopIsCollapsible = false;
      private _mobileMenuIsShown = false;

      public get mobileMenuIsShown(): boolean {
        if (this.mobileMenuCanBeShown) {
          return this._mobileMenuIsShown;
        }

        return false;
      }

      public get expandedLayoutTop(): boolean {
        if (!this.layoutTopIsCollapsible) return true;
        return !this.isMobile;
      }

      public get contentIsDark(): boolean {
        return this.darkContentIsAllowed && this.isMobile;
      }

      public get mobileMenuCanBeShown(): boolean {
        return this.isMobile;
      }

      public get isMobile(): boolean {
        return Browser.screen.lessThan[ScreenSize.Small];
      }

      @Action
      public toggleMobileMenu(shouldOpen?: boolean): void {
        if (typeof shouldOpen !== 'boolean') shouldOpen = !this._mobileMenuIsShown;
        this.MOBILE_MENU_IS_SHOWN(shouldOpen);
      }

      @Action
      public openMobileMenu(): void {
        this.toggleMobileMenu(true);
      }

      @Action
      public closeMobileMenu(): void {
        this.toggleMobileMenu(false);
      }

      @Mutation
      public IS_LOADING_CURRENT_USER(isLoadingCurrentUser: boolean): void {
        this.isLoadingCurrentUser = isLoadingCurrentUser;
      }

      @Mutation
      public IS_LOADING_BOOKS_LIST(isLoadingBooksList: boolean): void {
        this.isLoadingBooksList = isLoadingBooksList;
      }

      @Mutation
      public IS_LOADING_BOOK_DATA(isLoadingBookData: boolean): void {
        this.isLoadingBookData = isLoadingBookData;
      }

      @Mutation
      public DARK_CONTENT_IS_ALLOWED(value: boolean): void {
        this.darkContentIsAllowed = value;
      }

      @Mutation
      public LAYOUT_TOP_IS_COLLAPSIBLE(value: boolean): void {
        this.layoutTopIsCollapsible = value;
      }

      @Mutation
      private MOBILE_MENU_IS_SHOWN(isShown: boolean): void {
        this._mobileMenuIsShown = isShown;
      }
    }

    this.Module = BookshelfLayout;
  }
}

export type BookshelfLayoutModule = InstanceType<BookshelfLayoutModuleFactory['Module']>;
