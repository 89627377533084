
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';

@Component({
  components: {
    PlainModal,
    PremiumIcon,
  },
})
export default class SubscriptionBaseModal extends ClientSharedVue {
  @Prop({required: true})
  public id: string;

  @Prop({required: true})
  public title: string;
}
