<template>
  <rbe-grid-line>
    <transition name="fade">
      <button
        v-if="withButton"
        class="button-text button-black"
        @click="showMore"
      >
        Load more books
      </button>
    </transition>
  </rbe-grid-line>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';

@Component({})
export default class GridLine extends BookshelfVue {
  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Prop({type: Boolean, default: false})
  public withButton: boolean;

  public showMore(): void {
    this.$bookshelf.showMoreBookshelfEntries();
  }
}
</script>

<style lang="scss" scoped>
rbe-grid-line {
  position: relative;
  border-bottom: $border;

  $button-width: 12rem;
  $button-height: calc($font-size-base + 2 * $space-md);

  button {
    position: absolute;
    left: calc(50% - math.div($button-width, 2));
    top: calc($button-height / -2);
    min-width: $button-width;
    border-radius: $border-radius-xxl;
  }
}
</style>
