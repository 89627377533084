import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-panel-body-title", null, [
    _createElementVNode("h2", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createTextVNode(),
    _renderSlot(_ctx.$slots, "premium-info", {}, undefined, true)
  ]))
}