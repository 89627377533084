import {ANALYTICS_COOKIE} from '@reedsy/studio.isomorphic/helpers/analytics/analytics-cookie';
import {IGaIdentity} from '@reedsy/studio.isomorphic/helpers/analytics/i-ga-identity';
import {IClientConfig} from '@reedsy/studio.shared/config';
import {YEARS} from '@reedsy/utils.date';
import cookie from 'cookie';

export async function setAnalyticsCookie(config: IClientConfig): Promise<void> {
  const dataLayer: any[] = (window as any).dataLayer;

  const [clientId, sessionId] = await Promise.all([
    get('client_id'),
    get('session_id'),
  ]);
  const identity: IGaIdentity = {clientId, sessionId};

  document.cookie = cookie.serialize(ANALYTICS_COOKIE, JSON.stringify(identity), {
    path: '/',
    // Match GA cookie expiry of 2 years:
    // https://support.google.com/analytics/answer/11397207?hl=en
    expires: new Date(Date.now() + 2 * YEARS),
  });

  function get(fieldName: string): Promise<string> {
    return new Promise((resolve) => gtag('get', config.gtm.tagId, fieldName, resolve));
  }

  function gtag(..._args: any[]): void {
  // Need to use arguments, otherwise this doesn't work properly
  // https://developers.google.com/tag-platform/tag-manager/datalayer
  // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments);
  }
}
