import {VuexModule, Mutation} from '@reedsy/vuex-module-decorators';
import IModal from './i-modal';
import {IModalsModule} from '@reedsy/studio.shared/store/modules/modals/i-modals-module';
import * as uuid from 'uuid';

export type ModalType<M> = keyof M & string;
export type ModalConfig<M, T extends ModalType<M>> = {id?: string; type: T} &
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
(M[T] extends never ? {} : {context: M[T]});

export abstract class SharedModals<M> extends VuexModule implements IModalsModule {
  public abstract readonly modalsById: {[id: string]: IModal};
  protected readonly cancelableModals: Promise<Record<string, boolean>> = Promise.resolve({});

  public get modals(): IModal[] {
    return Object.values(this.modalsById);
  }

  public get hasOpenModals(): boolean {
    return !!this.modals.length;
  }

  @Mutation
  public CONFIRMING({id, confirming}: IConfirming): void {
    if (!this.modalsById[id]) return;
    this.modalsById[id].confirming = confirming;
  }

  public OPEN<T extends ModalType<M>>(type: M[T] extends never ? T : never): Promise<void>;
  public OPEN<T extends ModalType<M>>(config: ModalConfig<M, T>): Promise<void>;

  @Mutation
  public async OPEN<T extends ModalType<M>>(config: T | ModalConfig<M, T>): Promise<void> {
    let modal: IModal;
    const cancelableModals = await this.cancelableModals;
    if (typeof config === 'string') {
      modal = {
        id: uuid.v4(),
        cancelable: cancelableModals[config],
        type: config,
      };
    } else {
      modal = {
        id: config.id || uuid.v4(),
        cancelable: cancelableModals[config.type],
        ...config,
      };
    }

    this.modalsById[modal.id] = modal;
  }

  @Mutation
  public CLOSE(id: string): void {
    if (!this.modalsById[id]) return;
    delete this.modalsById[id];
  }

  @Mutation
  public CLOSE_ALL(): void {
    const modals = Object.values(this.modalsById);

    const allAreClosable = modals
      .every((modal) => {
        return modal.cancelable && !modal.confirming;
      });

    if (!allAreClosable) return;

    modals.forEach((modal) => delete this.modalsById[modal.id]);
  }
}

export interface IConfirming {
  id: string; confirming: boolean;
}
export interface ICancelable {
  id: string; cancelable: boolean;
}
