import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-78513116"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full-width" }
const _hoisted_2 = ["aria-label", "disabled"]
const _hoisted_3 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntryAction = _resolveComponent("EntryAction")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_EntryAction, { class: "entry-action flex-top" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("rbe-tooltip", _hoisted_1, [
        _createElementVNode("button", {
          class: "button button-text button-md full-width button-accent restore-book-button",
          type: "button",
          "aria-label": `Restore book ${_ctx.book.title}`,
          disabled: !_ctx.isUserBookOwner,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openRestoreBookModal && _ctx.openRestoreBookModal(...args)))
        }, "\n        Restore\n      ", 8, _hoisted_2)
      ])), [
        [
          _directive_tooltip,
          _ctx.restoreButtonTooltip,
          void 0,
          { top: true }
        ]
      ]),
      _createTextVNode(),
      _createElementVNode("a", {
        class: "button button-text button-md full-width button-neutral open-in-editor-button",
        href: _ctx.bookEditorLink,
        target: "_blank",
        "aria-label": `Continue writing ${_ctx.book.title}`
      }, "\n      Open\n    ", 8, _hoisted_3)
    ]),
    _: 1
  }))
}