import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4ad43350"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide-entry" }
const _hoisted_2 = ["href", "aria-label"]
const _hoisted_3 = { class: "ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCover = _resolveComponent("BookCover")!
  const _component_ShareIndicator = _resolveComponent("ShareIndicator")!

  return (_openBlock(), _createElementBlock("rbe-book-entry", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.bookEditorLink,
      target: "_blank",
      class: "book-link",
      "aria-label": `Continue writing ${_ctx.book.title}`
    }, [
      _createVNode(_component_BookCover, {
        book: _ctx.book,
        class: "book-cover"
      }, null, 8, ["book"]),
      _createTextVNode(),
      _createElementVNode("rbe-book-title", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.book.title), 1)
      ])
    ], 8, _hoisted_2),
    _createTextVNode(),
    _createVNode(_component_ShareIndicator, {
      class: "share-indicator",
      "book-id": _ctx.book.id
    }, null, 8, ["book-id"])
  ]))
}