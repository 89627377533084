// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--tracked-deletion-strikethrough-color: transparent}`, "",{"version":3,"sources":["data:;charset=utf-8,@use%20'sass:color';%0A%0A:root%20%7B%0A%20%20--tracked-deletion-strikethrough-color:%20transparent;%0A%7D%0A%0A@function%20color($colorSet,%20$variation:%20base,%20$changes...)%20%7B%0A%20%20$color:%20vuiGetColor($colorSet,%20$variation);%0A%0A%20%20@return%20color.change($color,%20$changes...);%0A%7D%0A%0A$black:%20$vui-black;%0A$blue:%20$vui-blue;%0A$green:%20$vui-green;%0A$grey:%20$vui-grey;%0A$red:%20$vui-red;%0A$white:%20$vui-white;%0A$yellow:%20$vui-yellow;%0A$slate:%20$vui-slate;%0A$translucent:%20$vui-translucent;%0A$shade-base:%20%23121B20;%0A$shade:%20(%0A%20%20fade:%20color.change($shade-base,%20$alpha:%200.1),%0A%20%20light:%20color.change($shade-base,%20$alpha:%200.2),%0A%20%20base:%20color.change($shade-base,%20$alpha:%200.5),%0A%20%20dark:%20color.change($shade-base,%20$alpha:%200.7),%0A);%0A$link-color:%20%231255CC;%0A"],"names":[],"mappings":"AAEA,MACE,mDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpoint-sm": `600px`,
	"breakpoint-md": `950px`,
	"breakpoint-lg": `1200px`,
	"transition-fast": `.15s`,
	"transition-base": `.3s`,
	"transition-slow": `.6s`,
	"rem": `16px`,
	"size-widget-height": `480`,
	"size-widget-headerHeight": `3rem`,
	"size-checkIn-minHeight": `13.8rem`,
	"space-xxxs": `.0625rem`,
	"space-xxs": `.125rem`,
	"space-xs": `.25rem`,
	"space-sm": `.5rem`,
	"space-md": `.75rem`,
	"space-base": `1rem`,
	"space-lg": `1.25rem`,
	"space-xl": `1.5rem`,
	"space-xxl": `1.75rem`,
	"space-xxxl": `2rem`,
	"borderRadius-xs": `2px`,
	"borderRadius-sm": `4px`,
	"borderRadius-base": `6px`,
	"borderRadius-lg": `8px`,
	"borderRadius-xl": `16px`,
	"borderRadius-xxl": `32px`
};
export default ___CSS_LOADER_EXPORT___;
