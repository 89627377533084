import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "themed dark-theme" }
const _hoisted_2 = { class: "overflow-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookTopBar = _resolveComponent("BookTopBar")!
  const _component_BookSkeletonLoader = _resolveComponent("BookSkeletonLoader")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_BookTopInfo = _resolveComponent("BookTopInfo")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_ctx.book)
    ? (_openBlock(), _createBlock(_component_MainLayout, { key: 0 }, {
        "top-part": _withCtx(() => [
          _createElementVNode("rbe-top-part-wrapper", _hoisted_1, [
            _createVNode(_component_BookTopBar),
            _createTextVNode(),
            (_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_BookSkeletonLoader, {
                  key: 0,
                  "data-test": "skeleton-loader"
                }))
              : (_openBlock(), _createBlock(_component_BookTopInfo, {
                  key: 1,
                  book: _ctx.book
                }, {
                  "book-subtitle": _withCtx(() => [
                    _createVNode(_component_RouterView, { name: "routeDescription" })
                  ]),
                  _: 1
                }, 8, ["book"]))
          ])
        ]),
        "above-content": _withCtx(() => [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_RouterView, {
                key: 0,
                name: "aboveContent"
              }))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createTextVNode(),
          _createTextVNode(),
          _createElementVNode("rbe-book-content", _hoisted_2, [
            (!_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_RouterView, {
                  key: 0,
                  "data-test": "router-view"
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}