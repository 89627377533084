<template>
  <rbe-panel-body-title>
    <h2>
      <slot />
    </h2>
    <slot name="premium-info" />
  </rbe-panel-body-title>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import PremiumBadgeInfo from '@reedsy/studio.shared/components/subscriptions/premium-badge-info.vue';
import PremiumInfo from '@reedsy/studio.shared/components/subscriptions/premium-info.vue';
import {config} from '@reedsy/studio.shared/config';

@Component({
  components: {
    PremiumInfo,
    PremiumBadgeInfo,
  },
})
export default class PanelBodyTitle extends BookshelfVue {
  public readonly config = config;
}
</script>

<style lang="scss" scoped>
rbe-panel-body-title {
  margin: $space-base 0 $space-sm 0;
  display: flex;
  align-items: center;
  gap: $space-sm;

  &.no-top-space {
    margin-top: 0;
  }

  &.small {
    h2 {
      font-size: $font-size-base;
    }
  }

  h2 {
    font-size: $font-size-lg;
  }

  rbe-premium-badge-info {
    cursor: pointer;
  }
}
</style>
