<template>
  <BookshelfEntry
    class="bookshelf-entry"
    :class="coverImageClass"
  >
    <a
      :href="bookEditorLink"
      target="_blank"
      class="book-link"
      :aria-label="`Continue writing ${book.title}`"
    >
      <rbe-book
        :class="coverImageClass"
      >
        <BookCover :book="book" />
      </rbe-book>
      <BookTitle
        :title="book.title"
        :author="author"
        class="hover-book-title"
      />
    </a>

    <ShareIndicator
      class="share-indicator"
      :book-id="book.id"
    />

    <template #bottom>
      <EntryAction
        v-if="hasGoalSet"
        class="blurred-background"
        :class="coverImageClass"
      >
        <BookProgress
          :book="book"
          class="book-entry-progress"
        />
      </EntryAction>
    </template>

    <template #bottom-hover>
      <BookEntryActions
        :book="book"
        :class="coverImageClass"
        @click.stop=""
      />
    </template>
  </BookshelfEntry>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import BookProgress from '@reedsy/studio.home.bookshelf/components/book-progress/book-progress.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {BookshelfEntryType, IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {BooksTotalWordCountsModule} from '@reedsy/studio.home.bookshelf/store/modules/books-total-word-counts';
import BookshelfEntry from './bookshelf-entry.vue';
import BookEntryActions from './book-entry-actions.vue';
import EntryAction from './entry-action.vue';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import BookTitle from '@reedsy/studio.home.bookshelf/components/book-cover/book-title.vue';
import ShareIndicator from '@reedsy/studio.home.bookshelf/components/share-indicator/share-indicator.vue';

@Component({
  components: {
    BookCover,
    BookProgress,
    BookshelfEntry,
    BookEntryActions,
    EntryAction,
    BookTitle,
    ShareIndicator,
  },
})
export default class BookEntry extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @$lazyInjectStore(StoreName.BooksTotalWordCounts)
  public $totalWordCounts: BooksTotalWordCountsModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry<BookshelfEntryType.Book>;

  public get book(): IBookshelfBook {
    return this.entry.item;
  }

  public get hasGoalSet(): boolean {
    return this.$bookshelf.hasGoalSet(this.book);
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }

  public get coverImageClass(): string {
    return this.book.coverUrl ? 'with-cover-image' : 'without-cover-image';
  }

  public get author(): IUserInfo {
    return this.$collaborators.userById[this.book.ownerId];
  }
}
</script>

<style lang="scss" scoped>
@mixin background-gradient-opacity($base-color) {
  backdrop-filter: blur(0.5rem);
  background-image: linear-gradient(
    210deg,
    rgba($base-color, 0.6) 0%,
    rgba($base-color, 0.75) 40%,
    rgba($base-color, 0.25) 70%,
    rgba($base-color, 0.5) 100%
  );
}

.bookshelf-entry {
  position: relative;

  &.without-cover-image {
    .hover-book-title {
      display: none;
    }
  }

  &.with-cover-image {
    .hover-book-title {
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      padding: $space-xl $space-md $space-lg calc(5% + $space-md);
      opacity: 0;

      @include transition(opacity);
    }

    // cover spine
    :deep(rbe-bookshelf-entry) {
      &::after {
        opacity: 0;

        @include cover-spine;
        @include background-gradient-opacity($cover-placeholder-spine-color);
        @include transition((opacity, backdrop-filter));
      }
    }

    :deep(rbe-book) {
      rbe-book-cover {
        &::after {
          opacity: 0;
          display: block;
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: $cover-placeholder-spine-border-radius;

          @include background-gradient-opacity(#fff);
          @include transition((opacity, backdrop-filter));
        }
      }
    }

    &:hover {
      .hover-book-title {
        opacity: 1;
      }

      :deep(rbe-bookshelf-entry) {
        &::after {
          opacity: 1;
        }
      }

      :deep(rbe-book) {
        rbe-book-cover {
          &::after {
            opacity: 1;
          }
        }
      }

      rbe-book-title {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .book-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  rbe-book {
    cursor: pointer;
    width: 100%;
    height: 100%;

    rbe-book-cover {
      width: 100%;
      height: 100%;
    }
  }

  rbe-entry-action {
    .book-entry-progress {
      flex: 1;
    }

    &.with-cover-image {
      .skeleton-loader {
        background-color: color($shade, fade);
      }
    }
  }
}
</style>
