<template>
  <Panel
    title="Leave book"
    class="leave-book-panel"
  >
    <p ref="text">
      This book belongs to {{ bookOwnerName }}.
      Clicking the button below will remove the book from your bookshelf,
      and you will lose access to it.
    </p>
    <button
      ref="leaveBookButton"
      type="button"
      class="delete-book-button button-text accent-danger"
      @click="openConfirmationModal"
    >
      Leave book
    </button>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';

@Component({
  components: {
    Panel,
  },
})
export default class LeaveBookPanel extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  public get bookOwnerName(): string {
    return this.$currentBook.owner.userInfo.name;
  }

  public async openConfirmationModal(): Promise<void> {
    await this.$modals.OPEN({
      type: 'ConfirmLeaveBook',
      context: {
        bookId: this.$currentBook.id,
        bookTitle: this.$currentBook.data.title,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.leave-book-panel {
  margin-top: $space-xl;

  .delete-book-button {
    --delete-book-button-color: var(--accent-color);

    margin-top: $space-base;
    background-color: transparent;
    color: var(--delete-book-button-color);
    border: $border-width-lg solid var(--delete-book-button-color);

    &:hover {
      --delete-book-button-color: var(--accent-color-strong);
    }

    @include screen-less-than(sm) {
      width: 100%;
    }
  }
}
</style>
