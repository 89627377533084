import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4200b12e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "vui-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileMenu = _resolveComponent("MobileMenu")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!
  const _component_LayoutFooter = _resolveComponent("LayoutFooter")!

  return (_openBlock(), _createElementBlock("rbe-main-layout", {
    class: _normalizeClass({
      'inverted-background': !_ctx.$layout.contentIsDark
    })
  }, [
    _createVNode(_component_MobileMenu),
    _createTextVNode(),
    _createElementVNode("rbe-top-part-wrapper", null, [
      _createVNode(_component_TopBar),
      _createTextVNode(),
      _createVNode(_component_ExpandTransition, null, {
        default: _withCtx(() => [
          (_ctx.$layout.expandedLayoutTop)
            ? (_openBlock(), _createElementBlock("rbe-top-slot-wrapper", _hoisted_1, [
                _renderSlot(_ctx.$slots, "top-part", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _createTextVNode(),
    _renderSlot(_ctx.$slots, "above-content", {}, undefined, true),
    _createTextVNode(),
    _createElementVNode("rbe-content", {
      class: _normalizeClass(["themed vui-wrapper", {
        'light-theme': !_ctx.$layout.contentIsDark,
        'dark-theme': _ctx.$layout.contentIsDark,
      }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createTextVNode(),
    _createElementVNode("rbe-footer-wrapper", null, [
      _createVNode(_component_LayoutFooter, { class: "bookshelf-layout-footer" })
    ])
  ], 2))
}