<template>
  <ConfirmModal
    :id="id"
    title="Offline"
    class="accent-secondary"
  >
    <div class="offline-modal">
      <VuiLoadingIndicator class="color-accent-alt" />
      <span class="text">
        Oops! Looks like you’re offline. Trying to reconnect...
      </span>
    </div>
  </ConfirmModal>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class OfflineModal extends mixins(ModalMixin, ClientSharedVue) {
  public readonly cancelable = false;
}
</script>

<style lang="scss" scoped>
.offline-modal {
  display: flex;
  align-items: center;

  .text {
    margin-left: $space-base;
  }
}
</style>
