<template>
  <div
    v-if="userInfo || placeholder"
    class="avatar"
    :class="{
      placeholder
    }"
    :style="avatarStyles"
    :title="name"
  >
    <template v-if="!placeholder">
      <img
        v-if="avatarSrc"
        :src="avatarSrc"
        :alt="name"
      >
      <div
        v-else
        ref="initials"
        class="initials"
      >
        {{ initials }}
      </div>
      <div
        v-if="activityIndicator"
        class="activity-indicator"
        :class="{
          active
        }"
      />
    </template>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {HTMLImage} from '@reedsy/studio.shared/utils/html/html-image';
import {getInitials} from '@reedsy/studio.isomorphic/utils/get-initials';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import {CSSProperties, PropType} from 'vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {INITIALS_PLACEHOLDER} from '@reedsy/studio.shared/components/avatar/initials-placeholder';

const logger = loggerFactory.create('Avatar');
@Component({})
export default class Avatar extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public activityIndicator: boolean;

  @Prop({type: Boolean, default: false})
  public active: boolean;

  @Prop({type: Object as PropType<IUserInfo>, default: null})
  public userInfo: IUserInfo;

  @Prop({type: Boolean, default: false})
  public placeholder: boolean;

  public avatarSrc = '';

  public get name(): string {
    return this.userInfo?.name || 'Anonymous';
  }

  public get initials(): string {
    return getInitials(
      this.userInfo.firstName,
      this.userInfo.lastName,
    ) || INITIALS_PLACEHOLDER;
  }

  public get color(): string {
    return this.userInfo.color;
  }

  public get avatarStyles(): CSSProperties {
    if (this.placeholder) return {};

    return {
      background: this.color,
      borderColor: this.color,
    };
  }

  @Watch('userInfo')
  public async watchUserInfo(): Promise<void> {
    await this.loadAvatar();
  }

  public async mounted(): Promise<void> {
    await this.loadAvatar();
  }

  public async loadAvatar(): Promise<void> {
    const avatarSrc = this.userInfo?.avatarUrl;
    if (!avatarSrc) return;

    try {
      await HTMLImage.fetch(avatarSrc);
      this.avatarSrc = avatarSrc;
    } catch (e) {
      // Just swallow image load errors. They're benign, and we shouldn't
      // alert if an Avatar can't be loaded.
      logger.debug(e);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './avatar';
</style>
