
import {Prop, Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import IModal from '@reedsy/studio.shared/store/modules/modals/i-modal';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';

@Component({
  components: {
    LoadingButton,
  },
})
export default class PlainModal extends mixins(ModalMixin) {
  @Prop({type: String})
  public title: string;

  @Prop({type: Boolean, default: false})
  public headless: boolean;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public get modal(): IModal {
    return this.$modals.modalsById[this.id];
  }

  public get cancelable(): boolean {
    return !!this.modal?.cancelable;
  }
}
