<template>
  <rbe-book-top-bar>
    <rbe-controls-wrapper>
      <BookBackLink class="book-back-link" />
      <rbe-right-side>
        <rbe-book-page-selector>
          <BookPageSelector />
        </rbe-book-page-selector>
        <rbe-continue-writing-button class="hidden-until-md">
          <ContinueWritingButton :book="$currentBook.data" />
        </rbe-continue-writing-button>
      </rbe-right-side>
    </rbe-controls-wrapper>
  </rbe-book-top-bar>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookPageSelector from './book-page-selector.vue';
import BookBackLink from './book-back-link.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import ContinueWritingButton from '@reedsy/studio.home.bookshelf/components/continue-writing-button/continue-writing-button.vue';

@Component({
  components: {
    BookPageSelector,
    BookBackLink,
    ContinueWritingButton,
  },
})
export default class BookTopBar extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;
}
</script>

<style lang="scss">
.orientation-landscape-left .bookshelf-top-bar {
  padding-left: calc($space-md + env(safe-area-inset-left))!important;
}

.orientation-landscape-right .bookshelf-top-bar {
  padding-right: calc($space-md + env(safe-area-inset-right))!important;
}
</style>

<style lang="scss" scoped>
rbe-book-top-bar {
  margin-left: -#{$space-xl};
  margin-right: -#{$space-xl};
  margin-top: $space-md;
  border-radius: $border-radius-lg;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $bookshelf-top-bar-height;
  background-color: #{color($translucent)};

  @include font-family($controls, bold);

  rbe-controls-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    padding-left: $space-md;
    padding-right: $space-md;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    .book-back-link, rbe-book-page-selector {
      height: 100%;
    }

    .book-back-link {
      box-sizing: border-box;
      margin-right: 0;

      @include screen-more-than(sm) {
        margin-right: $space-lg;
      }
    }

    rbe-right-side {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;

      rbe-book-page-selector :deep(.tab-wrapper:first-child) {
        @include screen-more-than(sm) {
          padding-left: 0;
        }
      }

      rbe-continue-writing-button {
        margin-right: $space-md;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
