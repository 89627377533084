<template>
  <span class="new-export-button">
    <span>New export</span>
    <span class="button button-icon button-xs button-accent">
      <VuiIconPlus class="icon-sm" />
    </span>
  </span>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({
  components: {},
})
export default class NewExportButton extends BookshelfVue {
}
</script>

<style lang="scss" scoped>
.new-export-button .button {
  margin: $space-xs 0 $space-xs $space-sm;
}
</style>
