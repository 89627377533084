<template>
  <VDropdown
    popper-class="v-popper--no-arrow v-popper--no-padding"
    placement="bottom-end"
  >
    <slot name="trigger">
      <ContextMenuButton />
    </slot>
    <template #popper>
      <rbe-context-menu
        v-close-popper
        class="v-popper__context-menu"
        :class="{
          'condensed': condensed
        }"
      >
        <slot />
      </rbe-context-menu>
    </template>
  </VDropdown>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ContextMenuButton from './context-menu-button.vue';

@Component({
  components: {
    ContextMenuButton,
  },
})
export default class ContextMenu extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public condensed: boolean;
}
</script>

<style lang="scss">
/* stylelint-disable-next-line selector-class-pattern */
.v-popper__context-menu {
  display: flex;
  flex-direction: column;
  padding: $space-md 0;
  min-width: 6rem;
  gap: $space-sm;

  & > button {
    padding: 0 $space-md !important;
    justify-content: flex-start;

    @include font-family($controls);

    &:hover {
      color: var(--accent-color);
    }
  }

  &.condensed {
    padding: $space-xs 0;
    min-width: unset;
    gap: unset;

    & > button {
      padding: 0 $space-sm !important;
    }
  }

}
</style>
