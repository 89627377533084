import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3153ed3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "book-skeleton-loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!
  const _component_BookTopInfoGrid = _resolveComponent("BookTopInfoGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BookTopInfoGrid, null, {
      "book-cover": _withCtx(() => [
        _createVNode(_component_SkeletonLoader, { class: "book-cover" })
      ]),
      "book-titles": _withCtx(() => [
        _createVNode(_component_SkeletonLoader, { class: "book-title" }),
        _createTextVNode(),
        _createVNode(_component_SkeletonLoader, { class: "book-subtitle" })
      ]),
      _: 1
    })
  ]))
}