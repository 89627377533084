<template>
  <span class="logo logo-text">
    <span>reedsy</span><span
      v-if="withAppName"
      class="logo-app-text"
    >bookeditor</span>
  </span>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class Logo extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public withAppName: boolean;
}
</script>

<style lang="scss" scoped>
.logo {
  @include font-family($logo);

  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: $font-size-logo;
  height: 100%;

  &.reverse-color {
    color: var(--text-color);
  }

  .logo-app-text {
    color: var(--accent-color);
  }
}
</style>
