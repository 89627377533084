import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-77c2950e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hide-on-hover" }
const _hoisted_2 = { class: "show-on-hover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyRenderer = _resolveComponent("LazyRenderer")!

  return (_openBlock(), _createBlock(_component_LazyRenderer, { class: "lazy-rendered-entry" }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-bookshelf-entry", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        _createTextVNode(),
        _createElementVNode("rbe-entry-bottom", _hoisted_1, [
          _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
        ]),
        _createTextVNode(),
        _createElementVNode("rbe-entry-bottom", _hoisted_2, [
          _renderSlot(_ctx.$slots, "bottom-hover", {}, undefined, true)
        ])
      ])
    ]),
    _: 3
  }))
}