<template>
  <rbe-books-list>
    <transition
      name="fade"
      mode="out-in"
    >
      <MobileBooksList
        v-if="$layout.isMobile"
        class="mobile-view"
      />
      <BooksGrid
        v-else
        class="desktop-book-list"
      />
    </transition>
  </rbe-books-list>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import BooksGrid from '@reedsy/studio.home.bookshelf/components/books-grid/books-grid.vue';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import MobileBooksList from '@reedsy/studio.home.bookshelf/components/mobile-books-list/mobile-books-list.vue';
import {IImportBookModalArgs} from '@reedsy/studio.home.bookshelf/components/modals/i-import-book-modal-args';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import Notify from '@reedsy/studio.shared/services/notify/notify';

@Component({
  components: {
    BooksGrid,
    MobileBooksList,
  },
})
export default class BooksList extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  public async mounted(): Promise<void> {
    if (this.$route.params.action !== 'import') return;

    // Need to get values from the query, so that the data is still available after
    // the $router.replace call
    const modalContext = {
      modalTitle: this.$route.query['modal-title'],
      modalDescription: this.$route.query['modal-description'],
      bookTitle: this.$route.query['book-title'],
      bookSubtitle: this.$route.query['book-subtitle'],
      manuscriptFilename: this.$route.query['manuscript-filename'],
      source: this.$route.query.source,
    } as IImportBookModalArgs;
    const expectedUserUuid = this.$route.query['user-uuid'] as string;

    await this.$router.replace({name: BookshelfRouteName.BooksList});

    if (!this.userUuidMatch(expectedUserUuid)) {
      return Notify.error({message: 'Unable to import book from another user'});
    }

    this.$modals.OPEN({
      type: 'ImportBook',
      context: modalContext,
    });
  }

  private userUuidMatch(expectedUserUuid: string): boolean {
    if (typeof expectedUserUuid !== 'string') return false;
    return expectedUserUuid.toLocaleLowerCase() === this.$user.uuid.toLocaleLowerCase();
  }
}
</script>

<style lang="scss" scoped>
rbe-books-list {
  flex: 1;
  display: flex;
  flex-direction: column;

  @include screen-more-than(sm) {
    padding-bottom: 4rem;
  }

  .desktop-book-list {
    margin-top: $space-xxl;
  }
}
</style>
