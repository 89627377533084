
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ItemsBox from '@reedsy/studio.shared/components/items-box.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {UserSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-settings';
import {TimeOfDay} from '@reedsy/utils.date';
import {config} from '@reedsy/studio.shared/config';
import {humanReadableTime} from '@reedsy/studio.shared/filters/human-readable-time';
import PanelBodyTitle from '@reedsy/studio.home.bookshelf/components/panel/panel-body-title.vue';
import {PREMIUM_FEATURES_DESCRIPTIONS} from '@reedsy/studio.shared/literals/premium-feature-descriptions';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {IPaidFeature} from '@reedsy/utils.subscription';
import PremiumInfo from '@reedsy/studio.shared/components/subscriptions/premium-info.vue';

@Component({
  components: {
    ItemsBox,
    PanelBodyTitle,
    PremiumInfo,
  },
})
export default class CheckInNotificationsSettings extends BookshelfVue {
  @$lazyInjectStore(StoreName.UserSettings)
  public readonly userSettings: UserSettingsModule;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public readonly subscription: SharedSubscriptionModule;

  public readonly paidFeature: IPaidFeature = 'checkIns';
  public readonly premiumDescriptions = PREMIUM_FEATURES_DESCRIPTIONS;

  public get checkInEmailTime(): string {
    return this.userSettings.settings.notifications.checkIn;
  }

  public set checkInEmailTime(value: string) {
    this.userSettings.setCheckInNotificationTime(value);
  }

  public get humanReadableTime(): string {
    return humanReadableTime(TimeOfDay.parse(this.checkInEmailTime));
  }

  public get featureEnabled(): boolean {
    return this.subscription.hasFeature(this.paidFeature);
  }

  public get isSubscriptionsFeatureEnabled(): boolean {
    return config.features.subscriptions;
  }
}
