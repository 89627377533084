
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

@Component({
  components: {
  },
})
export default class ExportThemePreview extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get relativeImagePath(): string {
    const numberText = this.$bookExportSubmission.chapterNumber ? 'number' : 'no_number';
    const dropCapText = this.$bookExportSubmission.dropCaps ? 'dropcap' : 'no_dropcap';
    const themeText = this.$bookExportSubmission.theme;

    return `${themeText}/${numberText}-${dropCapText}.png`;
  }

  public get themeImage(): string {
    return require(`@/assets/inlined/theme/preview/${this.relativeImagePath}`);
  }
}
