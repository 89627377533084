import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiToggle = _resolveComponent("VuiToggle")!

  return (_openBlock(), _createBlock(_component_VuiToggle, {
    modelValue: _ctx.notifications,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notifications) = $event)),
    class: "label-left spread toggle-input",
    label: _ctx.goal.title
  }, null, 8, ["modelValue", "label"]))
}