import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1dcce4c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-left flex-gap" }
const _hoisted_2 = { class: "flex-left flex-gap status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollaboratorPlaceholder = _resolveComponent("CollaboratorPlaceholder")!
  const _component_AutoLoadAvatar = _resolveComponent("AutoLoadAvatar")!
  const _component_TickIcon = _resolveComponent("TickIcon")!
  const _component_CollaboratorContextMenu = _resolveComponent("CollaboratorContextMenu")!

  return (_openBlock(), _createElementBlock("rbe-collaborator", null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_CollaboratorPlaceholder, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_AutoLoadAvatar, {
              "book-uuid": _ctx.currentBook.uuid,
              "user-uuid": _ctx.collaboratorUuid,
              class: "hidden-until-sm avatar"
            }, null, 8, ["book-uuid", "user-uuid"]),
            _createTextVNode(),
            _createElementVNode("p", {
              ref: "collaboratorName",
              class: "hidden-until-sm ellipsis"
            }, _toDisplayString(_ctx.collaborator.name), 513),
            _createTextVNode(),
            _createElementVNode("p", {
              ref: "collaboratorShortName",
              class: "visible-until-xs ellipsis"
            }, _toDisplayString(_ctx.collaboratorShortName), 513)
          ]),
          _createTextVNode(),
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode("\n        Accepted\n        "),
            _createVNode(_component_TickIcon)
          ]),
          _createTextVNode(),
          _createVNode(_component_CollaboratorContextMenu, {
            collaborator: _ctx.collaborator,
            book: _ctx.currentBook.data
          }, null, 8, ["collaborator", "book"])
        ], 64))
  ]))
}