<template>
  <VForm
    ref="form"
    class="modal reedsy-accented"
    :disabled="confirming || null"
    @submit="submit"
  >
    <header class="modal-head">
      <span class="ellipsis">{{ title }}</span>

      <button
        v-if="cancelable"
        class="button-icon button-sm close-modal-button reedsy-accented"
        type="button"
        :disabled="confirming || null"
        @click="close"
      >
        <VuiIconCross class="base-icon" />
      </button>
    </header>

    <rbe-modal-content>
      <slot />
    </rbe-modal-content>

    <footer
      v-if="confirm || cancelable"
      class="modal-footer"
    >
      <rbe-modal-actions v-if="hasExtraActions">
        <slot name="extra-actions" />
      </rbe-modal-actions>
      <button
        v-if="cancelable"
        ref="cancel"
        class="button-text cancel-button"
        type="button"
        :disabled="confirming || cancelDisabled || null"
        @click="close"
      >
        {{ cancelLabel }}
      </button>
      <LoadingButton
        v-if="confirm"
        ref="confirm"
        type="submit"
        :disabled="confirming || confirmDisabled || null"
        :loading="confirming"
        class="button reedsy-accented button-text"
      >
        {{ confirm.label }}
      </LoadingButton>
    </footer>
  </VForm>
</template>

<script lang="ts">
import {Prop, Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {timeout} from '@reedsy/utils.timeout';
import {IConfirmButton, ICancelButton} from '@reedsy/studio.shared/components/modals/i-modal-button';
import {PropType} from 'vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import IModal from '@reedsy/studio.shared/store/modules/modals/i-modal';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';

@Component({
  components: {
    LoadingButton,
  },
})
export default class ConfirmModal extends mixins(ModalMixin) {
  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: Object as PropType<ICancelButton>})
  public cancel: ICancelButton;

  @Prop({type: Object as PropType<IConfirmButton>})
  public confirm: IConfirmButton;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public get modal(): IModal {
    return this.$modals.modalsById[this.id];
  }

  public get cancelLabel(): string {
    return this.cancel?.label || 'Cancel';
  }

  public get cancelDisabled(): boolean {
    return !!this.cancel?.disabled;
  }

  public get confirmDisabled(): boolean {
    return !!this.confirm?.disabled;
  }

  public get confirming(): boolean {
    return !!this.modal?.confirming;
  }

  public get cancelable(): boolean {
    return !!this.modal?.cancelable;
  }

  public get hasExtraActions(): boolean {
    return !!this.$slots['extra-actions'];
  }

  public async submit(): Promise<void> {
    if (this.confirming) return;
    try {
      this.$modals.CONFIRMING({id: this.id, confirming: true});
      await timeout(); // Let the loading spinner update
      if (typeof this.confirm?.handler === 'function') await this.confirm.handler();
      this.$modals.CONFIRMING({id: this.id, confirming: false});
      this.close();
    } catch (error) {
      this.$modals.CONFIRMING({id: this.id, confirming: false});
      throw error;
    }
  }
}
</script>
