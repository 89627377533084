import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "vui-radio-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBodyTitle = _resolveComponent("PanelBodyTitle")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_TabOptions = _resolveComponent("TabOptions")!
  const _component_VuiRadio = _resolveComponent("VuiRadio")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    "body-title": "Formatting options",
    class: "book-formatting-selector"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelBodyTitle, { class: "no-top-space" }, {
        default: _withCtx(() => [
          _createTextVNode("\n      Book cover\n    ")
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createElementVNode("p", null, [
        _createTextVNode("\n      You can edit your book cover in\n      "),
        _createVNode(_component_RouterLink, {
          ref: "settingsLink",
          to: _ctx.bookSettingsRoute,
          class: "settings-link link"
        }, {
          default: _withCtx(() => [
            _createTextVNode("\n        \n        \n        Settings")
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(".\n    ")
      ]),
      _createTextVNode(),
      _createVNode(_component_PanelBodyTitle, null, {
        default: _withCtx(() => [
          _createTextVNode("\n      Formatting options\n    ")
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createVNode(_component_TabOptions, {
        ref: "formattingOptions",
        modelValue: _ctx.selectedFormatting,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFormatting) = $event)),
        options: _ctx.formattingOptions,
        "is-multiple": true
      }, null, 8, ["modelValue", "options"]),
      _createTextVNode(),
      _createVNode(_component_PanelBodyTitle, null, {
        default: _withCtx(() => [
          _createTextVNode("\n      End note positioning\n    ")
        ]),
        _: 1
      }),
      _createTextVNode(),
      _createElementVNode("rbe-notes-position-radio-group", _hoisted_1, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.isPdfExport)
              ? (_openBlock(), _createBlock(_component_VuiRadio, {
                  key: 0,
                  ref: "endnoteRadioEndOfPage",
                  modelValue: _ctx.endnotesPosition,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endnotesPosition) = $event)),
                  "radio-value": _ctx.NotesPosition.EndOfPage,
                  text: "At the end of page"
                }, null, 8, ["modelValue", "radio-value"]))
              : (_openBlock(), _createBlock(_component_VuiRadio, {
                  key: 1,
                  ref: "endnoteRadioEndOfChapter",
                  modelValue: _ctx.endnotesPosition,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.endnotesPosition) = $event)),
                  "radio-value": _ctx.NotesPosition.EndOfChapter,
                  text: "At the end of each chapter"
                }, null, 8, ["modelValue", "radio-value"]))
          ]),
          _: 1
        }),
        _createTextVNode(),
        _createVNode(_component_VuiRadio, {
          ref: "endnoteRadioEndOfBook",
          modelValue: _ctx.endnotesPosition,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.endnotesPosition) = $event)),
          "radio-value": _ctx.NotesPosition.EndOfBook,
          text: "At the end of the book, in a separate chapter"
        }, null, 8, ["modelValue", "radio-value"])
      ])
    ]),
    _: 1
  }))
}