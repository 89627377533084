import IDebugEvent from './i-debug-event';
import stringify from 'json-stringify-safe';
import {config} from '@reedsy/studio.shared/config';

export const EVENTS: IDebugEvent[] = [];

export function debugEvent(name: string, event: string, data?: any): void {
  const maxEvents = config.logging.maxDebugEvents;
  if (EVENTS.length >= maxEvents) EVENTS.splice(0, EVENTS.length - maxEvents + 1);

  const now = new Date();
  const mainTime = [now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()]
    .map((segment) => segment.toString().padStart(2, '0'))
    .join(':');
  const millis = now.getUTCMilliseconds().toString().padStart(3, '0');
  const time = `${mainTime}.${millis}`;
  const log: IDebugEvent = {time, name, event};
  if (data !== undefined) log.data = stringify(data);
  EVENTS.push(log);
}

// Expose on window for inspection
(window as any).reedsyEvents = EVENTS;
