import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconDrop = _resolveComponent("IconDrop")!
  const _component_VuiIconArrowLeft = _resolveComponent("VuiIconArrowLeft")!

  return (_openBlock(), _createElementBlock("rbe-screen-edge-arrow", null, [
    _createVNode(_component_IconDrop, { class: "arrow-background" }),
    _createTextVNode(),
    _createVNode(_component_VuiIconArrowLeft, { class: "arrow-icon icon-md" })
  ]))
}