import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewBookActionBar = _resolveComponent("NewBookActionBar")!
  const _component_BookLoader = _resolveComponent("BookLoader")!
  const _component_BooksSlider = _resolveComponent("BooksSlider")!
  const _component_NoBooksPlaceholder = _resolveComponent("NoBooksPlaceholder")!
  const _component_SelectedBookActionBar = _resolveComponent("SelectedBookActionBar")!

  return (_openBlock(), _createElementBlock("rbe-mobile-books-list", null, [
    _createVNode(_component_NewBookActionBar, { class: "new-book-action-bar" }),
    _createTextVNode(),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.isLoadingBooksList)
          ? (_openBlock(), _createBlock(_component_BookLoader, { key: 0 }))
          : (_ctx.bookshelfEntries.length)
            ? (_openBlock(), _createBlock(_component_BooksSlider, {
                key: 1,
                ref: "booksSlider"
              }, null, 512))
            : (_openBlock(), _createBlock(_component_NoBooksPlaceholder, { key: 2 }))
      ]),
      _: 1
    }),
    _createTextVNode(),
    _createVNode(_component_SelectedBookActionBar, { class: "selected-book-action-bar" })
  ]))
}