<template>
  <rbe-export-theme-preview>
    <transition
      name="fade"
      mode="out-in"
    >
      <img
        :key="relativeImagePath"
        :src="themeImage"
      >
    </transition>
  </rbe-export-theme-preview>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

@Component({
  components: {
  },
})
export default class ExportThemePreview extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get relativeImagePath(): string {
    const numberText = this.$bookExportSubmission.chapterNumber ? 'number' : 'no_number';
    const dropCapText = this.$bookExportSubmission.dropCaps ? 'dropcap' : 'no_dropcap';
    const themeText = this.$bookExportSubmission.theme;

    return `${themeText}/${numberText}-${dropCapText}.png`;
  }

  public get themeImage(): string {
    return require(`@/assets/inlined/theme/preview/${this.relativeImagePath}`);
  }
}
</script>

<style lang="scss" scoped>
rbe-export-theme-preview {
  padding: 3rem 4rem 0;
  background-color: var(--NEUTRAL-COLOR-weak);
  border-radius: $border-radius-base;
  overflow: hidden;

  @include screen-less-than(sm) {
    padding: $space-md 0 0 0;
  }

  img {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    display: block;
    box-shadow: $box-shadow-base;
    aspect-ratio: 1.025;
    width: 100%;
  }
}
</style>
