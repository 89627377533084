<template>
  <rbe-premium-icon v-if="config.features.subscriptions">
    <VuiIconBolt :class="`icon-circle icon-${size}`" />
  </rbe-premium-icon>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {config} from '@reedsy/studio.shared/config';

@Component({})
export default class PremiumIcon extends ClientSharedVue {
  @Prop({default: 'xs'})
  public size: string;

  public readonly config = config;
}
</script>

<style lang="scss" scoped>
rbe-premium-icon {
  display: flex;
  color: var(--TEXT-INVERTED-COLOR);

  .icon-circle {
    background: var(--PREMIUM-COLOR);
  }
}
</style>
