import * as t from '@reedsy/utils.io-ts';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import {V2ExportOptions} from '@reedsy/schemas.workers.book-export';

export const ExportPayload = t.type({
  type: t.enumValue(ExportType),
  options: V2ExportOptions,
});

export type IExportPayload = t.TypeOf<typeof ExportPayload>;
