<template>
  <Panel
    title="Manage access"
    class="manage-access-panel"
  >
    <rbe-content class="flex-top">
      <template v-if="hasItems">
        <Collaborator
          v-for="collaborator in collaborators"
          :key="collaborator.uuid"
          :collaborator-uuid="collaborator.uuid"
          class="manage-access-item-grid"
        />

        <Invitation
          v-for="invitation in currentBook.invitations"
          :key="invitation.shortId"
          :invitation="invitation"
          class="manage-access-item-grid"
        />
      </template>
      <NoCollaboratorsPlaceholder v-else />
    </rbe-content>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {ICollaboratorInfo} from '@reedsy/studio.home.bookshelf/store/modules/book-details/i-collaborator-info';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {byRole} from '@reedsy/studio.isomorphic/utils/sort/by-role';
import Invitation from './invitation.vue';
import Collaborator from './collaborator.vue';
import NoCollaboratorsPlaceholder from './no-collaborators-placeholder.vue';

@Component({
  components: {
    Panel,
    Invitation,
    Collaborator,
    NoCollaboratorsPlaceholder,
  },
})
export default class ManageAccessPanel extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public currentBook: CurrentBookModule;

  @$lazyInjectStore(SharedStoreName.User)
  public currentUser: SharedUserModule;

  public get hasItems(): boolean {
    return !!(
      this.collaborators.length +
      this.currentBook.invitations.length
    );
  }

  public get collaborators(): ICollaboratorInfo[] {
    return this.currentBook
      .collaborators
      .filter((collaborator) => collaborator.uuid !== this.currentUser.uuid)
      .sort(byRole);
  }
}
</script>

<style lang="scss" scoped>
.manage-access-panel .manage-access-item-grid {
  max-width: 100%;
  display: grid;
  grid-template:
    'name status action' min-content
    / minmax(0, 2fr) 1fr min-content;
  align-items: center;
  gap: $space-sm;
}

rbe-content {
  gap: $space-base;
}

@include screen-less-than(sm) {
  .manage-access-panel .manage-access-item-grid {
    grid-template:
      'name status action' min-content
      / minmax(0, 2fr) min-content min-content;
  }
}
</style>
