import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d883182"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "books-slider-button-prev" }
const _hoisted_2 = { class: "books-slider-button-next" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenEdgeArrow = _resolveComponent("ScreenEdgeArrow")!

  return (_openBlock(), _createElementBlock("rbe-books-slider", null, [
    _createElementVNode("rbe-slide-control", _hoisted_1, [
      _createVNode(_component_ScreenEdgeArrow, { class: "left" })
    ]),
    _createTextVNode(),
    _createElementVNode("rbe-slide-control", _hoisted_2, [
      _createVNode(_component_ScreenEdgeArrow, { class: "right" })
    ]),
    _createTextVNode(),
    _createElementVNode("swiper-container", {
      ref: "swiper",
      class: "swiper-books-slider",
      effect: "coverflow",
      "grab-cursor": "true",
      "centered-slides": "true",
      "slides-per-view": "auto",
      "space-between": "30",
      "events-prefix": "swiper-",
      "coverflow-effect-rotate": "20",
      "coverflow-effect-scale": "0.9",
      "coverflow-effect-stretch": "0",
      "coverflow-effect-depth": "100",
      "coverflow-effect-modifier": "1",
      "coverflow-slide-shadows": "false",
      "scrollbar-enabled": "",
      "scrollbar-draggable": "true",
      "scrollbar-snap-on-release": "true",
      "navigation-enabled": "true",
      "navigation-prev-el": ".books-slider-button-prev",
      "navigation-next-el": ".books-slider-button-next",
      onSwiperScrollbardragend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateActiveEntry && _ctx.updateActiveEntry(...args))),
      onSwiperSlidechange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateActiveEntry && _ctx.updateActiveEntry(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookshelfEntries, (entry) => {
        return (_openBlock(), _createElementBlock("swiper-slide", {
          key: entry.id
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentMap[entry.type]), { entry: entry }, null, 8, ["entry"]))
        ]))
      }), 128))
    ], 544)
  ]))
}