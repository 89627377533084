import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e6749574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "emphasized" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    title: "Resend invitation",
    cancel: {label: 'Cancel'},
    confirm: {
      label: 'Resend',
      handler: _ctx.resendInvitation,
    }
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-confirm-resend-invitation-modal", null, [
        _createElementVNode("p", null, [
          _createTextVNode("\n        Are you sure you want to resend a collaboration invitation to\n        "),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.context.email), 1),
          _createTextVNode("?\n      ")
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "confirm"]))
}