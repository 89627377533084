import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {Store} from 'vuex';
import {IBookshelfBookShareDb} from '@reedsy/studio.home.bookshelf/services/i-bookshelf-sharedb';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {clone} from '@reedsy/utils.clone';
import {Doc} from 'sharedb/lib/client';
import {IBookshelfBookModule} from '@reedsy/studio.home.bookshelf/store/helpers/i-bookshelf-book-module';
import {memoize} from '@reedsy/utils.object';
import {IInitBookData} from '@reedsy/studio.home.bookshelf/store/helpers/i-book-data-store-coordinator';
import {compare} from '@reedsy/utils.sort';
import {ICollaborationInvitation} from '@reedsy/reedsy-sharedb/lib/common/collaboration/collaboration-invitations.js';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

// eslint-disable-next-line
export default function collaborationInvitationsDataModuleFactory({bookUuid}: IInitBookData, bookShareDb: IBookshelfBookShareDb, store: Store<any>) {
  const name = `bookshelf_collaborationInvitations_${bookUuid}`;

  @Module({name, store})
  class CollaborationInvitationsDataModule extends VuexModule implements IBookshelfBookModule {
    public invitationsById: {[invitationId: string]: ICollaborationInvitation} = {};

    public get allInvitations(): ICollaborationInvitation[] {
      return Object.entries(this.invitationsById)
        .map(([invitationId, invitation]) => {
          return {
            _id: invitationId,
            ...invitation,
          };
        })
        .sort((invitation1, invitation2) => {
          return compare(
            invitation1.createdAt,
            invitation2.createdAt,
            {descending: true},
          );
        });
    }

    @Mutation
    public DATA({id, data}: IDataMutation<'collaborationInvitations'>): void {
      this.invitationsById[id] = {
        ...clone(data),
        _id: id,
      };
    }

    @Mutation
    public APPLY_OPS({id, ops}: IApplyOpsMutation<'collaborationInvitations'>): void {
      ops.forEach((op) => applyOp(this.invitationsById[id], op));
    }

    @Action
    @memoize
    public async initialise(): Promise<void> {
      const query = await bookShareDb.subscribeQuery('collaborationInvitations');

      const subscribeDocs = (docs: Doc<ICollaborationInvitation>[]): void => {
        docs.forEach((doc) => subscribeVuexToDoc(doc, this));
      };

      subscribeDocs(query.results);
      query.on('insert', subscribeDocs);
      query.on('remove', (removedDocs) => {
        removedDocs.forEach((doc) => {
          const invitationId = doc.id;
          this.REMOVE_INVITATION(invitationId);
          doc.destroy();
        });
      });
    }

    @Mutation
    private REMOVE_INVITATION(invitationId: string): void {
      delete this.invitationsById[invitationId];
    }
  }

  return CollaborationInvitationsDataModule;
}

export type CollaborationInvitationsDataModule = InstanceType<
  ReturnType<typeof collaborationInvitationsDataModuleFactory>
>;
