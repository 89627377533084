import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-756b5f34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel flex-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconWarning = _resolveComponent("VuiIconWarning")!

  return (_openBlock(), _createElementBlock("rbe-error-message-box", _hoisted_1, [
    (_ctx.withIcon)
      ? (_openBlock(), _createBlock(_component_VuiIconWarning, {
          key: 0,
          ref: "icon",
          class: "warning-icon icon-lg"
        }, null, 512))
      : _createCommentVNode("", true),
    _createTextVNode(),
    _createElementVNode("p", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}