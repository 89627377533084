import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-layout-with-right-side-bar", null, [
    _createElementVNode("rbe-left-column", null, [
      _renderSlot(_ctx.$slots, "left-side", {}, undefined, true)
    ]),
    _createTextVNode(),
    _createElementVNode("rbe-right-column", null, [
      _renderSlot(_ctx.$slots, "right-side", {}, undefined, true)
    ])
  ]))
}