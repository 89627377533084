import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {Store} from 'vuex';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import ColorPool from '@reedsy/studio.shared/services/color-pool/color-pool';
import {dig} from '@reedsy/utils.dig';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

const colorPool = new ColorPool();

@injectable()
export class SharedUserColorModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
  @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    User: SharedUserModule,
  ) {
    @Module({name: SharedStoreName.UserColor, store})
    class SharedUserColor extends VuexModule {
      private userColors: Record<string, string> = {};

      @Action
      public async getUserColor(userId: string): Promise<string> {
        if (!userId) return null;

        if (!this.userColors[userId]) {
          const isCurrentUser = (
            !!User.info && (
              userId === User.info._id ||
              userId.toString() === `${dig(User.info as any, 'legacyMarketplaceId')}`
            )
          );
          const userColor = isCurrentUser ? ColorPool.USER_GREEN : colorPool.get();
          this.USER_COLOR({userId, color: userColor});
        }
        return this.userColors[userId];
      }

      @Mutation
      private USER_COLOR({userId, color}: IUserColorRelation): void {
        this.userColors[userId] = color;
      }
    }

    this.Module = SharedUserColor;
  }
}

export type SharedUserColorModule = InstanceType<SharedUserColorModuleFactory['Module']>;
interface IUserColorRelation {
  userId: string;
  color: string;
}
