
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({
  components: {
    Panel,
    Avatar,
  },
})
export default class AuthAccountSettingsBanner extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInject('Navigation')
  public readonly _navigation: INavigation;

  public get user(): IUserInfo {
    return this.$user.info;
  };

  public get userName(): string {
    return this.user.name;
  }
}
