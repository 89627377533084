<template>
  <Panel class="book-backup">
    <rbe-book-backup-content>
      <img
        class="backup-icon"
        src="@/assets/inlined/backup-icon.svg"
      >
      <rbe-backup-book-text-wrapper>
        <h1>
          Back up your book
        </h1>
        <p>
          Need an editable version of your book as a backup? Export a simple .docx version of your manuscript.
        </p>
      </rbe-backup-book-text-wrapper>
      <LoadingButton
        ref="backupButton"
        class="button button-text backup-button button-accent"
        :loading="isSubmittingExportRequest"
        @click="exportDocx"
      >
        Back&nbsp;up <VuiIconDownload class="download-icon" />
      </LoadingButton>
    </rbe-book-backup-content>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.home.bookshelf/components/panel/panel.vue';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

@Component({
  components: {
    Panel,
    LoadingButton,
  },
})
export default class BookBackup extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get isSubmittingExportRequest(): boolean {
    return this.$bookExportSubmission.isSubmittingExportRequest;
  }

  public exportDocx(): void {
    this.$bookExportSubmission.exportDocx();
  }
}
</script>

<style lang="scss" scoped>
rbe-book-backup-content, rbe-backup-book-text-wrapper {
  display: block;
}

.book-backup {
  rbe-book-backup-content {
    display: flex;
    align-items: center;

    @include screen-less-than(md) {
      flex-direction: column;
    }

    .backup-icon {
      width: 4.5rem;
    }

    rbe-backup-book-text-wrapper {
      padding: 0 $space-lg 0 $space-md;

      @include screen-less-than(md) {
        padding: $space-md;
      }

      h1 {
        font-size: $font-size-lg;
        margin-bottom: $space-sm;
      }
    }

    .backup-button {
      white-space: nowrap;

      @include screen-less-than(md) {
        width: 100%;
      }

      .download-icon {
        margin-left: $space-sm;
      }
    }
  }
}
</style>
